/* eslint-disable import/prefer-default-export */
export enum Auth {
  isLoggedIn = 'isLoggedIn',
}

export enum Conversations {
  isConversionExists = 'isConversionExists',
  getMessagesListByConversation = 'getMessagesListByConversation',
  getLastServerNTime = 'getLastServerNTime',
  getConversationsCompletedQueries = 'getConversationsCompletedQueries',
  getConversationLastMessage = 'getConversationLastMessage',
  getOrderIdByConversationId = 'getOrderIdByConversationId'
}

export enum Profile {
  companyHasWorks = 'companyHasWorks',
  companyHasOrder = 'companyHasOrder',
  companyIsCustomer = 'companyIsCustomer',
  companyIsContractor = 'companyIsContractor',
  getUserId = 'getUserId'
}

export enum Reviews {
  getReviewsList = 'getReviewsList',
  getReviewsListLength = 'getReviewsListLength'
}
