























































import {
  ref,
  defineComponent,
} from 'vue-demi'

import { AvailableCompanyType } from '@/types/api'

import { useRouter } from '@/router'

export default defineComponent({
  components: {},

  setup() {
    const router = useRouter()

    const companyType = ref<AvailableCompanyType>('contractor')

    function toEnterInn() {
      return router.replace({
        query: { r: companyType.value },
      })
    }

    return {
      companyType,
      toEnterInn,
    }
  },
})

