



























import {
  ref,
  watch,
  toRefs,
  defineComponent,
} from 'vue-demi'

import { GeoSuggestion } from '@/types'

import { DaDataProvider } from '@/providers'

interface Props extends Record<string, any> {
  readonly value: string
  readonly label: string
  readonly disabled: boolean
  readonly cityFiasId: string
  readonly placeholder: string
}

export default defineComponent<Props>({
  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    cityFiasId: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: 'Start typing to Search',
    },
  },

  setup(props, { emit }) {
    const { cityFiasId, value: propValue } = toRefs(props)

    const entries = ref<GeoSuggestion[]>([])

    const search = ref('')

    const model = ref<Record<string, any>>({})

    const loading = ref<boolean>(false)

    function onInput({ value }: GeoSuggestion) {
      emit('input', value)
    }

    function onCleanAddressClick() {
      model.value = {}
      search.value = ''
      loading.value = false
      entries.value = []
      emit('clean-address')
    }

    watch(
      propValue,
      v => !v.length && onCleanAddressClick(),
    )

    watch(
      search,
      async query => {
        if (!query || !query.length || loading.value) { return }

        loading.value = true

        try {
          entries.value = await DaDataProvider.getAddressInfo({ query, locations: { city_fias_id: cityFiasId.value } })
        } catch (error) {
          console.error(error)
        } finally {
          loading.value = false
        }
      },
    )

    return {
      entries,
      loading,
      model,
      onCleanAddressClick,
      onInput,
      search,
    }
  },
})

