var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"pt-14",attrs:{"clipped":"","fixed":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"color":"transparent","dense":"","nav":""}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"608px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('ui.order.create'))}})],1)],1)]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[(_vm.companyKind === 'customer')?_c('v-dialog',{ref:"dialog",attrs:{"width":"608px"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('CreateOrderWidget',{on:{"close":function($event){_vm.modal = false}}})],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-list',{attrs:{"color":"transparent","dense":"","nav":""}},_vm._l((_vm.links),function(ref){
var id = ref.id;
var icon = ref.icon;
var title = ref.title;
var route = ref.route;
return _c('v-list-item',{key:id,attrs:{"link":"","to":route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(title))}})],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }