
























































import {
  ref,
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

import { format } from 'date-fns'

import { formatDistanceFromDateToNow, parseWithMask } from '@/utils/dates'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: new Date().toISOString(),
    },

    min: {
      type: String,
      default: null,
    },

    max: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: 'label',
    },

    placeholder: {
      type: String,
      default: 'placeholder',
    },

    appendIcon: {
      type: String,
      default: 'mdi-calendar',
    },

    showYears: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array,
      default: () => ([]),
    },
  },

  setup(props, { emit }) {
    const { value } = toRefs(props)

    const date = computed({
      get: () => value.value.substr(0, 10),
      set: v => emit('input', v),
    })

    const modal = ref(false)

    const formatted = computed(() => format(parseWithMask(date.value, 'yyyy-MM-dd'), 'dd.MM.yyyy'))

    const years = computed(() => (props.showYears ? formatDistanceFromDateToNow(date.value) : null))

    return {
      date,
      modal,
      years,
      formatted,
    }
  },
})

