import { RouteConfig } from 'vue-router/types'

import { OrdersNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const Order = () => import(/* webpackChunkName: "order" */ '@/pages/Order.vue')
const List = () => import(/* webpackChunkName: "order" */ '@/pages/OrderList.vue')

const routes: RouteConfig[] = [
  {
    path: OrdersNames.path,
    component: Wrapper,

    children: [
      {
        path: '',
        name: OrdersNames.LIST,
        component: List,
        props: ({ query }) => query,
      },

      {
        path: '',
        name: OrdersNames.CREATE,
        redirect: () => ({
          name: OrdersNames.LIST,
          query: { 'add-dialog': 'true' },
        }),
      },

      {
        path: ':id/:section?',
        name: OrdersNames.OVERVIEW,
        component: Order,
        props: ({ params }) => ({ ...params }),
      },
    ],
  },
]

export default routes
