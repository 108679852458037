/* eslint-disable import/prefer-default-export */
import { Conversation } from '@/types/api'

import { ConversationsProvider } from '@/providers'

import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class ConversationsRepository implements DefaultCrudRepository<
Conversation,
any,
any,
any,
any,
any,
any
> {
  private provider: ConversationsProvider;

  public constructor() {
    this.provider = new ConversationsProvider()
  }

  public create(data: any): Promise<any> {
    this.provider.getList(data, {})
    throw new Error('Method not implemented.')
  }

  public createMany(data: any[]): Promise<any[]> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public save(data: any): Promise<any> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public find(where: any) {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(where: any, search?: any, work_id?: number | string, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<Conversation[]> {
    const { results } = await this.provider.getList(where, {}, search)
    return results
  }

  public update(where: any, data?: any): Promise<any> {
    console.log('ConversationsRepository/findMany:', { where, data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<any> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }
}
