import {
  Maybe,
  GeoSuggestion,
  PartySuggestion,
} from '@/types'

import { useStaticI18n } from '@/i18n'

import {
  POST,
  httpHeaders,
  RequestConfig,
  BaseRestProvider,
} from './base'

export interface GeoDadataPayload {
  query: Maybe<string>
  count?: Maybe<number>
  locations?: {
    region?: Maybe<string> // Регион
    kladr_id?: Maybe<string> // Код КЛАДР
    area_fias_id?: Maybe<string> // Код ФИАС района в регионе
    city_fias_id?: Maybe<string> // Код ФИАС города
    region_fias_id?: Maybe<string> // Код ФИАС региона
    street_fias_id?: Maybe<string> // Код ФИАС улицы
    settlement_fias_id?: Maybe<string> // Код ФИАС нас. пункта
  }
}

export interface PartyDadataPayload {
  query: Maybe<string>
  count?: number // 10 Количество результатов (максимум — 300)
  kpp?: string // КПП для поиска по филиалам, см. пример
  branch_type?: string // Головная организация (MAIN) или филиал (BRANCH), см. пример
  type?: string // Юрлицо (LEGAL) или индивидуальный предприниматель (INDIVIDUAL), см. пример
}

function transformRequestConfig(config: RequestConfig) {
  config.headers = {
    [httpHeaders.contentTypeHeader]: 'application/json',
    [httpHeaders.authorizationHeader]: `Token ${process.env.VUE_APP_DADATA_API_TOKEN}`,
    ...config.headers,
  }
  return config
}

function constructPayload({ query, locations, count }: GeoDadataPayload) {
  return {
    query,
    count,
    locations: [locations].filter(Boolean),
    language: useStaticI18n().locale,
  }
}

class DaData extends BaseRestProvider {
  public constructor() {
    super(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
      transformRequestConfig,
    )
  }

  /**
   * Получить информацию о компании
   *
   */
  public async getCompanyInfoByParty(payload: PartyDadataPayload) {
    try {
      const { data } = await super[POST]<{ suggestions: PartySuggestion[] }>('/findById/party', payload)
      return data.suggestions
    } catch (error) {
      console.error('DaDataProvider/getCompanyInfoByParty', error)
      throw error.response
    }
  }

  public async getAddressInfo(payload: GeoDadataPayload) {
    try {
      const { data } = await super[POST]<{ suggestions: GeoSuggestion[] }>('/suggest/address', constructPayload(payload))
      return data.suggestions
    } catch (error) {
      console.error('DaDataProvider/getAddressInfo', error)
      throw error.response
    }
  }

  public async getById(id: GeoDadataPayload['query']) {
    try {
      const { data } = await super[POST]<{ suggestions: GeoSuggestion[] }>('/findById/address', constructPayload({ query: id }))
      return data.suggestions[0]
    } catch (error) {
      console.error('DaDataProvider/getAddressById', error)
      throw error.response
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const DaDataProvider = new DaData()
