import { SocketConversationMessage } from '@/types/api'

export function createPreviewUri(file: File | Blob): Promise<{ src: string; type: string }> {
  // if (!/\.(jpe?g|png|gif)$/i.test(file.name)) { return Promise.resolve({ src: '' }); }
  const reader = new FileReader()

  return new Promise(resolve => {
    reader.onload = () => {
      resolve({
        src: reader.result as string,
        type: getMimeType(reader.result as ArrayBuffer, file.type),
      })
    }
    reader.readAsDataURL(file)
  })
}

export function saveAsText(filename: string, content: any) {
  const element = document.createElement('a')

  element.setAttribute('href', `data:text/plain;charset=utf-8,${JSON.stringify(content)}`)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

// detect the actual image type
export function getMimeType(file: ArrayBuffer, fallback: string) {
  const byteArray = new Uint8Array(file).subarray(0, 4)

  const headers: string[] = []
  byteArray.forEach(byte => headers.push(byte.toString(16)))

  const header = headers.join('')

  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

/**
 * * @AlexQuidditch
 * нет идей, как это работает
 * @link https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data
 */
// @ts-ignore
// export const FormDataFromMap = (f => f(f))(h => f => f(x => h(h)(f)(x)))(f => fd => pk => d => {
//   if (d instanceof Object) {
//     console.log('start', d)
//     // eslint-disable-next-line consistent-return
//     Object.keys(d).forEach(k => {
//       const v = d[k]
//       if (pk) {
//         const isIndex = !Number.isNaN(Number(k))
//         k = `${pk}[${isIndex ? '' : k}]`
//       }
//       if (v instanceof Object && !(v instanceof Date) && !(v instanceof File)) {
//         let res = f(fd)(k)(v)
//         console.log(res)
//         for (let value of res.values()) {
//           console.log(value)
//         }
//         return res
//       }
//       if (v !== null) fd.append(k, v)
//     })
//   }
//   return fd
// })(new FormData())()

export function FormDataFromMap(data) {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

function buildFormData(formData: any, data: any, parentKey?: any) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      console.log(key)
      let addKey = parentKey
      // @ts-ignore
      // eslint-disable-next-line
      if (parseInt(key) == key) {
        addKey = `${parentKey}[]`
      } else {
        addKey = parentKey ? `${parentKey}[${key}]` : key
      }
      buildFormData(formData, data[key], addKey)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

export function checkImageFileFormat(messageToCheck: SocketConversationMessage) {
  if (messageToCheck.kind === 'image') {
    if (messageToCheck.file_format === 'png'
        || messageToCheck.file_format === 'jpeg'
        || messageToCheck.file_format === 'jpg'
        || messageToCheck.file_format === 'webp'
        || messageToCheck.file_format === 'gif'
        || messageToCheck.file_format === 'svg') {
      return true
    }
    return false
  }
}

export function checkFileFormat(messageToCheck: SocketConversationMessage) {
  if (messageToCheck.kind === 'file') {
    if (messageToCheck.file_format === 'pdf'
        || messageToCheck.file_format === 'xlsx'
        || messageToCheck.file_format === 'xls'
        || messageToCheck.file_format === 'csv'
        || messageToCheck.file_format === 'docx'
        || messageToCheck.file_format === 'doc'
        || messageToCheck.file_format === 'pptx'
        || messageToCheck.file_format === 'ppt'
        || messageToCheck.file_format === 'pages'
        || messageToCheck.file_format === 'keynote'
        || messageToCheck.file_format === 'numbers'
        || messageToCheck.file_format === 'odt'
    ) {
      return true
    } return false
  }
}

export function getFileLink(messageToGetFileLink: any) {
  if (messageToGetFileLink.file_format === 'pdf') {
    return require('../assets/chat_file_types/pdf.png')
  } if (messageToGetFileLink.file_format === 'xlsx') {
    return require('../assets/chat_file_types/xlsx.png')
  } if (messageToGetFileLink.file_format === 'xls') {
    return require('../assets/chat_file_types/xls.png')
  } if (messageToGetFileLink.file_format === 'docx') {
    return require('../assets/chat_file_types/docx.png')
  } if (messageToGetFileLink.file_format === 'doc') {
    return require('../assets/chat_file_types/doc.png')
  } if (messageToGetFileLink.file_format === 'pptx') {
    return require('../assets/chat_file_types/pptx.png')
  } if (messageToGetFileLink.file_format === 'ppt') {
    return require('../assets/chat_file_types/ppt.png')
  } if (messageToGetFileLink.file_format === 'pages') {
    return require('../assets/chat_file_types/pages.png')
  } if (messageToGetFileLink.file_format === 'keynote') {
    return require('../assets/chat_file_types/key.png')
  } if (messageToGetFileLink.file_format === 'numbers') {
    return require('../assets/chat_file_types/numbers.png')
  } if (messageToGetFileLink.file_format === 'odt') {
    return require('../assets/chat_file_types/odt.png')
  } if (messageToGetFileLink.file_format === 'csv') {
    return require('../assets/chat_file_types/csv.png')
  }
}
