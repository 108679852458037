/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

import { ConversationLastMessage, ConversationsParams, SocketConversationMessage, SocketMessagePayload } from '@/types/api'

import { Authorization } from '@/services'
import { ConversationsProvider, socketProvider } from '@/providers'
import { ConversationsRepository } from '@/repositories'

import { Conversation } from '@/types/store/conversations'
import { ACTIONS, GETTERS, MUTATIONS } from '../types'

const conversationsProvider = new ConversationsProvider()
const conversationsRepository = new ConversationsRepository()

const monthArray = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря']

const module: Module<RootState['Conversations'], RootState> = {
  namespaced: true,

  state: {
    topics: {
      conversations: [],
    },
    completedQueries: [],
  },

  getters: {
    [GETTERS.Conversations.isConversionExists]: state => (id: number | null) => {
      if (id || id !== 0) return false
      return state.topics.conversations.findIndex(conversation => conversation.id === id) > -1
    },

    [GETTERS.Conversations.getMessagesListByConversation]: state => (id: any) => {
      if (id == null) return []

      for (const conversation of state.topics.conversations) {
        if (conversation.id === id.id) return conversation.messages
      }
      return []
    },

    [GETTERS.Conversations.getOrderIdByConversationId]: state => (id: any) => {
      if (id == null) return null

      for (const conversation of state.topics.conversations) {
        if (conversation.id === id) return conversation.order_id
      }
      return null
    },

    [GETTERS.Conversations.getLastServerNTime]: state => (id: number) => {
      const index = state.topics.conversations.findIndex(conversation => conversation.id === id)
      if (index > -1) return state.topics.conversations[index].lastServerNTime
      return false
    },

    [GETTERS.Conversations.getConversationsCompletedQueries]: state => (id: number) => {
      const index = state.completedQueries.findIndex((conversationsQuery: any) => conversationsQuery.id === id)
      if (index > -1) return state.completedQueries[index].ntimes
      return false
    },

    [GETTERS.Conversations.getConversationLastMessage]: state => (id: number) => {
      const index = state.topics.conversations.findIndex(conversation => conversation.id === id)
      if (index > -1) return state.topics.conversations[index].last_message
      return false
    },
  },

  actions: {
    async [ACTIONS.Conversations.fetchConversationMessages]({ commit, dispatch, getters }, { id, direction, lastNTime }: ConversationsParams) {
      const isConversionExists = getters[GETTERS.Conversations.isConversionExists](id)
      if (isConversionExists) return
      if (id === 0) return

      try {
        if (!lastNTime) {
          await dispatch(ACTIONS.Conversations.fetchLastServerNTime, { id })
          lastNTime = getters[GETTERS.Conversations.getLastServerNTime](id)
        }

        if (lastNTime) {
          if (direction === 'up') {
            const completedQueries = getters[GETTERS.Conversations.getConversationsCompletedQueries](id)
            if (!!completedQueries && completedQueries.filter((ntime: any) => ntime === +lastNTime!).length) {
              return true
            }
          }
          const messages = await conversationsProvider.getMessages({ id, lastNTime, direction })

          let highestLastNtime = 0

          if (direction === 'down') {
            messages.results.forEach((message: any) => {
              if (highestLastNtime < message.ntime) highestLastNtime = message.ntime

              commit(MUTATIONS.CONVERSATIONS.ADD_CONVERSATION_MESSAGE, message)
            })
          } else if (direction === 'up') {
            messages.results.forEach((message: any) => {
              if (highestLastNtime < message.ntime) highestLastNtime = message.ntime

              commit(MUTATIONS.CONVERSATIONS.UNSHIFT_CONVERSATION_MESSAGE, message)
            })
            commit(MUTATIONS.CONVERSATIONS.ADD_COMPLETED_QUERY, { id, ntime: lastNTime })
          }

          commit(MUTATIONS.CONVERSATIONS.SET_LAST_SERVER_N_TIME, { id, lastServerNTime: highestLastNtime })
        }
      } catch (error) {
        console.error(ACTIONS.Conversations.fetchConversationsList, ':', error)
      }
    },

    async [ACTIONS.Conversations.setLastConversationMessageIfUnsent]({ commit }, { id, last_message }: ConversationsParams) {
      commit(MUTATIONS.CONVERSATIONS.SET_LAST_CONVERSATION_MESSAGE_IF_UNSENT, { id, last_message })
    },

    async [ACTIONS.Conversations.fetchLastServerNTime]({ commit, getters }, { id }: ConversationsParams) {
      const isConversionExists = getters[GETTERS.Conversations.isConversionExists](id)
      if (isConversionExists) return
      if (id === 0) return

      try {
        const lastServerNTime = await conversationsProvider.fetchLastServerNTime({ id })
        commit(MUTATIONS.CONVERSATIONS.SET_LAST_SERVER_N_TIME, { id, lastServerNTime })
      } catch (error) {
        console.error(ACTIONS.Conversations.fetchConversationsList, ':', error)
      }
    },

    async [ACTIONS.Conversations.fetchConversationsList]({ state, commit }, { search }) {
      try {
        const conversations = await conversationsRepository.findMany({}, search, undefined, {}, {})
        conversations?.map(elem => elem.messages = [])
        commit(MUTATIONS.CONVERSATIONS.SET_CONVERSATION_LIST, conversations)
        return state.topics.conversations
      } catch (error) {
        console.error(ACTIONS.Conversations.fetchConversationsList, ':', error)
      }
    },

    [ACTIONS.Conversations.createSubscription]({ dispatch }) {
      socketProvider.createSubscription({
        channel: 'ChatChannel',
        params: {
          token: Authorization.getTokens().bearer,
        },
        received: data => dispatch(ACTIONS.Conversations.processSubscriptionReceivedData, data),
        connected: () => { console.warn('ChatChannel connected') },
        disconnected: () => { console.warn('ChatChannel disconnected') },
      })
    },

    [ACTIONS.Conversations.processSubscriptionReceivedData]({ commit }, messages: SocketMessagePayload[]) {
      console.log('received:', messages)
      messages.forEach(message => {
        switch (message.topic) {
          case '#message':
            commit(MUTATIONS.CONVERSATIONS.ADD_CONVERSATION_MESSAGE, message.data)
            break

          case '#order':
            commit(MUTATIONS.CONVERSATIONS.ADD_ORDER_MESSAGE, message.data)
            break

          default:
            console.log('unknown message topic', message)
            break
        }
      })
    },
  },

  mutations: {
    [MUTATIONS.CONVERSATIONS.ADD_CONVERSATION_ITEM](state, { conversation_id, last_message, order_id, company_title, order_title, user_ids }: {conversation_id: number; last_message: ConversationLastMessage; order_id: number; order_title: string; company_title: string; user_ids: any[]}) {
      const isExist = state.topics.conversations.findIndex(({ id }) => id === conversation_id) > -1
      if (isExist) return

      state.topics.conversations.push({ id: conversation_id, order_id, order_title, company_title, user_ids, messages: [], last_message })
    },

    [MUTATIONS.CONVERSATIONS.SET_CONVERSATION_LIST](state, conversations: Conversation[]) {
      state.topics.conversations = []
      state.topics.conversations = conversations
    },

    [MUTATIONS.CONVERSATIONS.SET_LAST_SERVER_N_TIME](state, { id, lastServerNTime }) {
      const conversation_id = id
      if (!conversation_id) { throw new ReferenceError('Conversation id not found.') }

      const index = state.topics.conversations.findIndex(({ id }) => id === conversation_id)
      if (index > -1) state.topics.conversations[index].lastServerNTime = lastServerNTime
    },

    [MUTATIONS.CONVERSATIONS.SET_LAST_CONVERSATION_MESSAGE_IF_UNSENT](state, { id, last_message }) {
      const index = state.topics.conversations.findIndex(conversation => id === conversation.id)
      if (index > -1) state.topics.conversations[index].last_message = last_message
    },

    [MUTATIONS.CONVERSATIONS.CLEAR_CONVERSATIONS_LIST](state) {
      state.topics.conversations = []
    },

    [MUTATIONS.CONVERSATIONS.ADD_COMPLETED_QUERY](state, { id, ntime }) {
      const conversation_id = id
      if (!conversation_id) { throw new ReferenceError('Conversation id not found.') }
      const index = state.completedQueries.findIndex(({ id }: any) => id === conversation_id)

      if (index > -1) state.completedQueries[index].ntimes.push(+ntime)
      else state.completedQueries = [{ id, ntimes: [+ntime] }]
    },

    [MUTATIONS.CONVERSATIONS.ADD_CONVERSATION_MESSAGE](state, message: SocketConversationMessage) {
      const { conversation_id } = message
      const messageDate = new Date(+message.ntime * 1000)
      const messageDateString = `${messageDate.getDate()} ${monthArray[messageDate.getMonth()]}`
      if (!conversation_id) { throw new ReferenceError('Conversation id not found.') }

      if (!message.content) { return }

      const isExist = state.topics.conversations.findIndex(({ id }) => id === conversation_id) > -1
      if (!isExist) {
        state.topics.conversations.push({ id: conversation_id, order_id: 0, messages: [{ date: messageDateString, messages: [message] }], last_message: { author_id: message.user_id, date: message.updated_at, content: message.kind !== 'text' ? 'Вложение' : message.content } })
        return
      }

      state.topics.conversations.forEach(conversation => {
        if (conversation.id !== conversation_id) return

        const dateIndex = conversation.messages.findIndex(m => m.date === messageDateString)
        if (dateIndex <= -1) {
          conversation.messages.push({ date: messageDateString, messages: [message] })
          return
        }

        const isConversationMessageExists = conversation.messages[dateIndex].messages.findIndex(m => m.id === message.id) > -1
        if (isConversationMessageExists) return
        conversation.last_message = { author_id: message.user_id, date: message.updated_at, content: message.kind !== 'text' ? 'Вложение' : message.content }
        conversation.messages[dateIndex].messages.push(message)
      })
    },

    [MUTATIONS.CONVERSATIONS.UNSHIFT_CONVERSATION_MESSAGE](state, message: SocketConversationMessage) {
      const { conversation_id } = message
      const messageDate = new Date(+message.ntime * 1000)
      const messageDateString = `${messageDate.getDate()} ${monthArray[messageDate.getMonth()]}`
      if (!conversation_id) { throw new ReferenceError('Conversation id not found.') }

      if (!message.content) { return }

      const isExist = state.topics.conversations.findIndex(({ id }) => id === conversation_id) > -1
      if (!isExist) {
        state.topics.conversations.unshift({ id: conversation_id, order_id: 0, messages: [{ date: messageDateString, messages: [message] }], last_message: { author_id: message.user_id, date: message.updated_at, content: message.kind !== 'text' ? 'Вложение' : message.content } })
        return
      }

      state.topics.conversations.forEach(conversation => {
        if (conversation.id !== conversation_id) return

        const dateIndex = conversation.messages.findIndex(m => m.date === messageDateString)
        if (dateIndex <= -1) {
          conversation.messages.unshift({ date: messageDateString, messages: [message] })
          return
        }

        const isConversationMessageExists = conversation.messages[dateIndex].messages.findIndex(m => m.id === message.id) > -1
        if (isConversationMessageExists) return
        conversation.messages[dateIndex].messages.unshift(message)
      })
    },

    [MUTATIONS.CONVERSATIONS.ADD_ORDER_MESSAGE](state, message: any) {
      console.log('#order', message)
    },
  },

  modules: {},
}

export default module
