





































































import { ref, defineComponent } from 'vue-demi'

export default defineComponent({
  components: {},

  setup() {
    const searchValue = ref<string>('')

    const dialog = ref(false)

    return {
      dialog,
      searchValue,
    }
  },
})

