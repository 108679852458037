import { logApiRequest } from '@/services'
import {
  AuthPayload,
  AuthResult,
  RefreshTokenResult,
  SberAuthResult,
} from '@/types/api'

import { BaseRestProvider, GET, POST } from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

interface SbbolRedirectParams {
  code: string
  nonce: string
  state: string
}

const routes = {
  signIn: {
    method: POST,
    path: '/login',
  },
  authorize: {
    method: GET,
    path: '/authorize',
  },
  callbackSuccess: {
    path: '/callback/success',
    method: GET,
  },
  refreshToken: {
    method: POST,
    path: '/refresh',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class AuthProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/auth`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * signIn
   */
  public async signIn(payload: AuthPayload): Promise<AuthResult> {
    try {
      const { data } = await super[POST]<AuthResult>(routes.signIn.path, payload)
      logApiRequest('AuthProvider/signIn', payload, data)
      return data
    } catch (error: any) {
      console.error('AuthProvider/signIn', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }

  public async signInWithSberBusinessId(params?: SbbolRedirectParams) {
    const route = params
      ? super.getUrlWithParams(routes.callbackSuccess.path, params)
      : routes.authorize.path

    try {
      const { data } = await super[GET]<SberAuthResult>(route)
      logApiRequest('AuthProvider/signInWithSberBusinessId', null, data)
      return data
    } catch (error: any) {
      console.error('AuthProvider/signInWithSberBusinessId', { config: error.config, data: error.data })
      throw error
    }
  }

  /**
   * refreshToken
   */
  public async refreshToken(refreshToken: string) {
    const headers = { 'refresh-token': refreshToken }

    try {
      const { data } = await super[POST]<RefreshTokenResult>(routes.refreshToken.path, null, { headers })
      logApiRequest('AuthProvider/refreshToken', { refreshToken }, data)
      return data
    } catch (error: any) {
      console.error('AuthProvider/refreshToken', { config: error.config, data: error.data })
      throw error
    }
  }
}
