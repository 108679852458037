/* eslint-disable no-extend-native */
interface Array<T> {

  /**
   * Returns the first element.
   * Otherwise returns the first element in the iteration order,
   * equivalent to `this[0]`.
   */
  first(): T | undefined

  /**
   * Returns the last element.
   * Otherwise may iterate through the elements and returns the last one
   * seen.
   * Some iterables may have more efficient ways to find the last element
   * (for example a array can directly access the last element,
   * without iterating through the previous ones).
   */
  last(): T | undefined

  /**
   * Returns `true` if there are no elements in this collection.
   */
  isEmpty(): boolean

  /**
   * Returns true if there is at least one element in this collection.
   */
  isNotEmpty(): boolean

  /**
   * Removes all objects from this list;
   * the length of the list becomes zero.
   */
  clear(): void
}

// Inspired by Dart lang
if (!Array.prototype.clear) {
  Array.prototype.clear = function clear() {
    this.length = 0
  }
}

if (!Array.prototype.first) {
  Array.prototype.first = function first() {
    // eslint-disable-next-line id-blacklist
    if (!this.length) { return undefined }
    return this[0]
  }
}

if (!Array.prototype.last) {
  Array.prototype.last = function last() {
    // eslint-disable-next-line id-blacklist
    if (!this.length) { return undefined }
    return this[this.length - 1]
  }
}

if (!Array.prototype.isEmpty) {
  Array.prototype.isEmpty = function isEmpty() {
    // eslint-disable-next-line id-blacklist
    if (!this.length) { return undefined }
    return this[this.length - 1]
  }
}

if (!Array.prototype.isNotEmpty) {
  Array.prototype.isNotEmpty = function isNotEmpty() {
    // eslint-disable-next-line id-blacklist
    return Boolean(this.length)
  }
}
