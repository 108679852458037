export enum Interface {
  SET_DRAWER = 'setDrawer'
}

export enum Auth {
  processAuthFlow = 'processAuthFlow',
  processSignOut = 'processSignOut',
  setAuthState = 'setAuthState',
}

export enum Workers {
  loadList = 'loadList',
}

export enum Orders {
  loadList = 'loadList',
}

export enum Profile {
  loadProfile = 'loadProfile',
  loadMyCompany = 'loadMyCompany',
}

export enum Reviews {
  fetchReviewsList = 'fetchReviewsList'
}

export enum Marketplace {
  fetchMarketplaceItems = 'fetchMarketplaceItems'
}

export enum Companies {
}

export enum Notifications {
  createSubscription = 'createSubscription',
  removeNotificationItem = 'removeNotificationItem',
  processSubscriptionReceivedData = 'processSubscriptionReceivedData',
}

export enum Conversations {
  fetchConversationMessages = 'fetchConversationMessages',
  fetchConversationsList = 'fetchConversationsList',
  createSubscription = 'createSubscription',
  processSubscriptionReceivedData = 'processSubscriptionReceivedData',
  fetchLastServerNTime = 'fetchLastServerNTime',
  setLastConversationMessageIfUnsent = 'setLastConversationMessageIfUnsent'
}
