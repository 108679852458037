



















import { defineComponent } from 'vue-demi'

export default defineComponent({
  name: 'VCurrencyField',
  props: {
    value: {
      type: Number,
      default: null,
    },

    name: {
      type: String,
    },

    label: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    dense: {
      type: Boolean,
    },

    rounded: {
      type: Boolean,
    },

    outlined: {
      type: Boolean,
    },

    hideDetails: {
      type: Boolean,
    },

    rules: {
      type: Array,
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      formattedValue: null as number | null,
    }
  },

  watch: {
    value(value) {
      this.setValue(value)
    },
  },

  mounted() {
    this.setValue(this.value)
  },

  methods: {
    setValue(value: number) {
      // @ts-ignore
      this.$ci.setValue(this.$refs.input, value)
    },

    onInput(value: number) {
      // @ts-ignore
      this.$emit('input', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    },

    onChange(value: number) {
      // @ts-ignore
      this.$emit('change', this.$ci.getValue(this.$refs.input))
      this.formattedValue = value
    },
  },
})

