













































































import {
  ref,
  inject,
  computed,
  defineComponent,
} from 'vue-demi'
import { RawLocation } from 'vue-router/types'

import { Payload, SetDrawerPayload } from '@/types/store'

import { Routes } from '@/router'

import { useStore } from '@/store'
import { GETTERS, MODULES, MUTATIONS } from '@/store/types'

import AppLogo from './AppLogo.vue'

import AppHeaderProfile from './AppHeaderProfile.vue'
import AppHeaderProgress from './AppHeaderProgress.vue'

interface Link {
  id: number
  title: string
  route: RawLocation
}

export default defineComponent({
  components: {
    AppLogo,
    AppHeaderProfile,
    AppHeaderProgress,
  },

  setup() {
    const store = useStore()

    const isNotTablet = inject<boolean>('isNotTablet')

    const isLoggedIn = computed<boolean>(() => store.getters[`${MODULES.Auth}/${GETTERS.Auth.isLoggedIn}`])

    const drawer = computed<boolean>({
      get: () => store.state.Interface.drawers.menu,
      set: v => store.commit<Payload<SetDrawerPayload>>({
        type: `${MODULES.Interface}/${MUTATIONS.INTERFACE.SET_DRAWER}`,
        key: 'menu',
        value: v,
      }),
    })

    const links = ref<Link[]>([
      {
        id: 0,
        title: 'ui.marketplace',
        route: { name: Routes.of.Marketplace.LIST },
      },
      {
        id: 1,
        title: 'ui.my_orders',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 2,
        title: 'ui.my_company',
        route: { name: Routes.of.MyCompany.OVERVIEW },
      },
      {
        id: 3,
        title: 'ui.services',
        route: { name: Routes.of.Services.LIST },
      },
      {
        id: 4,
        title: 'ui.dialogs',
        route: { name: Routes.of.Dialogs.LIST },
      },
    ])

    return {
      links,
      drawer,
      isLoggedIn,
      isNotTablet,
    }
  },

  computed: {
    /**
     * * @AlexQuidditch
     *  useRoute для Vue 2 + Composition API работает криво
     * TODO: заменить при миграции на Vue 3
     */
    isAuthRoute() {
      const { name } = this.$route
      return name && name.includes(Routes.of.Auth.BASE)
    },
  },
})

