/* eslint-disable import/prefer-default-export */
import { createNameSpace } from '@/utils/helpers'

export abstract class AuthNames {
  public static get path() {
    return '/auth'
  }

  private static get nameSpace() {
    return createNameSpace('Auth')
  }

  public static get BASE() {
    return this.nameSpace.with('')
  }

  public static get LOGIN() {
    return this.nameSpace.with('login')
  }

  public static get SIGN_UP() {
    return this.nameSpace.with('signUp')
  }

  public static get SIGN_OUT() {
    return this.nameSpace.with('signOut')
  }

  // eslint-disable-next-line class-methods-use-this
  public static toJSON(): Record<string, any> {
    return {
      path: AuthNames.path,
      namespace: AuthNames.nameSpace,
      login: AuthNames.LOGIN,
      signUp: AuthNames.SIGN_UP,
    }
  }
}
