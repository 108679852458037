import {
  Conversation,
  ConversationCreateInput,
  ConversationMessage,
  ConversationsParams,
  ExternalConversationMessage,
  ListWithMetaData,
} from '@/types/api'

import { logApiRequest } from '@/services'

// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

import { FormDataFromMap } from '@/utils/files'
import {
  GET,
  POST,
  BaseRestProvider,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  create: {
    path: '',
  },
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  getMessages: {
    path: (id: string | number) => `/${id}/messages`,
  },
  sendMessage: {
    path: (id: string | number) => `/${id}/messages`,
  },
  getList: {
    path: '',
  },
  fetchLastServerNTime: {
    path: (id: string | number) => `/${id}/server_last_ntime`,
  },
}

// eslint-disable-next-line import/prefer-default-export
export class ConversationsProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/conversations`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * create - create a collection
   */
  public async create(params: ConversationCreateInput, options?: RequestConfig): Promise<Conversation> {
    try {
      const { data } = await super[POST]<{ conversation: Conversation }>(routes.create.path, params, options)

      logApiRequest(
        'ConversationsProvider/create',
        params,
        { data },
      )

      return data.conversation
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/create', { config: error.config, data: error.data })
      }

      throw error.data.errors
    }
  }

  /**
   * getOne - get one conversation
   */
  public async getOne(params: ConversationsParams, options?: RequestConfig): Promise<Conversation> {
    const url = routes.getOne.path(params.id)

    try {
      const { data } = await super[GET]<{ order: Conversation }>(url, options)

      logApiRequest(
        'ConversationsProvider/getOne',
        params,
        { data },
      )

      return data.order
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/getOne', { config: error.config, data: error.data })
      }

      throw error.data.errors
    }
  }

  /**
   * getList - get conversations collection
   */
  public async getList(params: any, options?: RequestConfig, search?: string): Promise<ListWithMetaData<Conversation>> {
    let url
    if (search) url = super.getUrlWithParams(routes.getList.path, { search })
    else url = routes.getList.path
    console.log(url)

    try {
      const { data } = await super[GET]<ListWithMetaData<Conversation>>(url, options)

      logApiRequest(
        'ConversationsProvider/getList',
        params,
        { data },
      )
      return data
    } catch (error : any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/getList', { config: error.config, data: error.data })
      }

      throw error.data.errors
    }
  }

  /**
   * sendMessage - send message to conversation
   */
  public async sendMessage({ id, type }: any, payload: any, options?: RequestConfig): Promise<ExternalConversationMessage | ExternalConversationMessage[]> {
    const url = routes.sendMessage.path(id)

    try {
      if (type === 'file') {
        const returnData = []

        // eslint-disable-next-line no-restricted-syntax
        for (const elem of payload) {
          const formData: any = {}
          formData['message[file]'] = elem
          formData['message[uuid]'] = uuidv4()
          console.log(formData)
          const form = FormDataFromMap(formData)
          console.log(form)
          const { data } = await super[POST]<{ message: ExternalConversationMessage }>(url, form, options)
          logApiRequest(
            'ConversationsProvider/sendMessage',
            { id, ...payload },
            { data },
          )
          returnData.push(data.message)
        }
        return returnData
      } if (type === 'string') {
        payload.uuid = uuidv4()
        const { data } = await super[POST]<{ message: ExternalConversationMessage }>(url, payload, options)
        logApiRequest(
          'ConversationsProvider/sendMessage',
          { id, ...payload },
          { data },
        )
        return data.message
      }
      throw new Error('Not a valid type of message')
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/sendMessage', { config: error.config, data: error.data })
      }
      throw error.data.errors
    }
  }

  /**
   * fetchLastServerNTime - fetch last message ntime to ignore not unique messages from conversation
   */
  public async fetchLastServerNTime({ id }: ConversationsParams, options?: RequestConfig): Promise<number> {
    const url = routes.fetchLastServerNTime.path(id)

    try {
      const { data } = await super[GET]<{ server_last_ntime: number }>(url, options)

      logApiRequest(
        'ConversationsProvider/fetchLastServerNTime',
        { id },
        { data },
      )
      return data.server_last_ntime
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/fetchLastServerNTime', { name: error.name, messsage: error.message, id })
      }

      throw error.data.errors
    }
  }

  public async getMessages({ id, lastNTime, direction }: ConversationsParams, options?: RequestConfig): Promise<ListWithMetaData<ConversationMessage>> {
    let url = routes.getMessages.path(id)

    try {
      if (lastNTime && direction && (direction === 'up' || direction === 'down')) {
        url += `?last_ntime=${lastNTime}&direction=${direction}`
      } else if (lastNTime) {
        url += `?last_ntime="${lastNTime}"`
      } else if (direction && (direction === 'up' || direction === 'down')) {
        url += `?direction="${direction}"`
      } else {
        throw new Error('Incorrect lastNTime and direction arguments. Direction should be "up" or "down", lastNTime should be defined.')
      }

      const { data } = await super[GET]<ListWithMetaData<ExternalConversationMessage>>(url, options)

      logApiRequest(
        'ConversationsProvider/getMessages',
        { id },
        { data },
      )

      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('ConversationsProvider/getMessages', {
          name: error.name, messsage: error.message, lastNTime, direction,
        })
      }

      throw error.data.errors
    }
  }
}

// TODO: implement logic for chat here
