























import {
  inject,
  defineComponent,
} from 'vue-demi'

import { Events } from '@/services'

import { onKeyUpEscape } from '@/utils/listeners'

import AppHeader from '@/components/layout/AppHeader.vue'
import AppSidebar from '@/components/layout/AppSidebar.vue'

export default defineComponent({
  inheritAttrs: false,

  components: {
    AppHeader,
    AppSidebar,
  },

  setup() {
    onKeyUpEscape(event => Events.emit('on-esc', event))

    const isNotTablet = inject<boolean>('isNotTablet')

    return {
      isNotTablet,
    }
  },
})

