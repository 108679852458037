import {
  ListWithMetaData,
  Work,
  WorksParams,
  WorkWhereInput,
} from '@/types/api'

import { logApiRequest } from '@/services'

import {
  GET,
  BaseRestProvider,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  getList: {
    path: '',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class WorksProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/works/collection`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * getOne - get one user
   */
  public async getOne({ id }: WorkWhereInput, options?: RequestConfig): Promise<Work> {
    try {
      const { data } = await super[GET]<{ work: Work }>(routes.getOne.path(id), options)
      logApiRequest('WorksProvider/getOne', { id }, data)
      return data.work
    } catch (error: any) {
      console.error('WorksProvider/getOne', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * getList - get user collection
   */
  public async getList(params: WorksParams, options?: RequestConfig): Promise<ListWithMetaData<Work>> {
    const url = super.getUrlWithParams(routes.getList.path, params)

    try {
      const { data } = await super[GET]<ListWithMetaData<Work>>(url, options)
      logApiRequest('WorksProvider/getList', params, data)
      return data
    } catch (error: any) {
      console.error('WorksProvider/getList', { config: error.config, data: error.data })
      throw error.response
    }
  }
}
