import ActionCable, { Cable, CreateMixin } from 'actioncable'

export interface SocketOptions {
  url: string
}

export interface ChannelOptions extends CreateMixin {
  channel: string
  params: Record<string, any>
}

export class SocketProvider {
  private agent: Cable;

  /**
   * constructor
   * @example initialization example
   *  const socketProvider = new SocketProvider({ url: '' });
   *  socketProvider.createSubscription({
   *    channel: '',
   *    params: {},
   *    received: data => { console.log('received:', { data }); },
   *    connected: () => { console.log('connected'); },
   *    disconnected: () => { console.log('disconnected'); },
   *  });
   */
  public constructor({ url }: SocketOptions) {
    this.agent = ActionCable.createConsumer(url)
  }

  /**
   * subscriptions
   */
  public subscriptions() {
    this.checkAgent()
    return this.agent.subscriptions
  }

  public createSubscription(options: ChannelOptions) {
    this.checkAgent()
    const { channel, params, ...actions } = options
    return this.agent.subscriptions.create({ channel, ...params }, actions)
  }

  /**
   * send
   */
  public send(message: any) {
    this.checkAgent()
    return this.agent.send(message)
  }

  /**
   * connect
   */
  public connect() {
    this.checkAgent()
    return this.agent.connect()
  }

  /**
   * disconnect
   */
  public disconnect() {
    this.checkAgent()
    return this.agent.disconnect()
  }

  /**
   * ensureActiveConnection
   */
  public ensureActiveConnection() {
    this.checkAgent()
    return this.agent.ensureActiveConnection()
  }

  private checkAgent() {
    if (!this.agent) {
      throw new ReferenceError(
        'SocketProvider agent not found.\n'
        + 'Try to initialize SocketProvider before getting subscriptions list.',
      )
    }
  }
}

export const socketProvider = new SocketProvider({ url: 'wss://marta-dev-backend.amelia-st.ru/cable' })
