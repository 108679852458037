/* eslint-disable import/prefer-default-export */

import { WorksProvider } from '@/providers'

import { Work, WorksParams } from '@/types/api'

import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class WorksRepository implements DefaultCrudRepository<
Work,
any,
any,
any,
WorksParams,
any,
any
> {
  private provider: WorksProvider;

  public constructor() {
    this.provider = new WorksProvider()
  }

  public create(data: any): Promise<any> {
    this.provider.getList(data, {})
    throw new Error('Method not implemented.')
  }

  public createMany(data: any[]): Promise<any[]> {
    console.log({ data })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public save(data: any): Promise<any> {
    console.log({ data })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public async find({ id }: WorksParams) {
    if (id) {
      const { results } = await this.provider.getList({ id })
      return results[0]
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(where: WorksParams, work_id?: number | string, orderBy?: any, search?: any, options?: EntityWhereManyInputOptions) {
    const { results } = await this.provider.getList(where, {})
    return results
  }

  public update(where: any, data?: any): Promise<any> {
    console.log({ data })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<any> {
    console.log({ where })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getList({}, {})
    throw new Error('Method not implemented.')
  }
}

export const worksRepository = new WorksRepository()
