/* eslint-disable import/prefer-default-export */

import { UsersProvider } from '@/providers'
import { UserParams } from '@/types/api'
import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class UsersRepository implements DefaultCrudRepository<
any,
any,
any,
any,
any,
any,
any
> {
  private provider: UsersProvider;

  public constructor() {
    this.provider = new UsersProvider()
  }

  public create(data: any): Promise<any> {
    this.provider.getList(data, {})
    throw new Error('Method not implemented.')
  }

  public createMany(data: any[]): Promise<any[]> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public save(data: any): Promise<any> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public find(where: { id: number }) {
    return this.provider.getOne(where, {})
  }

  public async findMany(where: any, orderBy?: any, work_id?: number | string, search?: any, options?: EntityWhereManyInputOptions) {
    console.log({ where, orderBy, options })
    const { results } = await this.provider.getList({}, {})
    return results
  }

  public update(where: UserParams, data: FormData): Promise<any> {
    return this.provider.update(where, data)
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<any> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }
}

export const usersRepository = new UsersRepository()
