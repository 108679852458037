import { AuthNames } from './auth'
import { DialogsNames } from './dialogs'
import { MeNames } from './me'
import { MyCompanyNames } from './myCompany'
import { OrdersNames } from './orders'
import { ProfilesNames } from './profiles'
import { ServicesNames } from './services'
import { CompaniesNames } from './companies'
import { WorkersNames } from './workers'
import { MarketplaceNames } from './marketplace'

const names = {
  Auth: AuthNames,
  Companies: CompaniesNames,
  Dialogs: DialogsNames,
  Me: MeNames,
  MyCompany: MyCompanyNames,
  Orders: OrdersNames,
  Profiles: ProfilesNames,
  Services: ServicesNames,
  Workers: WorkersNames,
  Marketplace: MarketplaceNames,
}

export {
  AuthNames,
  CompaniesNames,
  DialogsNames,
  MeNames,
  MyCompanyNames,
  OrdersNames,
  ProfilesNames,
  ServicesNames,
  WorkersNames,
  MarketplaceNames,
}

export default names
