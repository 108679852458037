/* eslint-disable no-lonely-if */
import { Route } from 'vue-router/types'

import { Routes } from '@/router'
import { Authorization } from '@/services'

export function checkAuth({ fullPath, matched }: Route, from: Route, next: Function) {
  if (process.env.NODE_ENV !== 'production') {
    console.log('checkAuth:', fullPath)
  }

  if (matched.some(record => record.meta.allowAnonymous)) {
    // этот путь разрешает отсутствие авторизации,
    next()
  } else {
    // проверяем есть ли токен, и если нет,
    // перенаправляем на страницу логина
    if (!Authorization.isLoggedIn()) {
      next({
        name: Routes.of.Auth.LOGIN,
        query: { redirect: fullPath },
      })
    } else {
      next()
    }
  }
}

export function checkDuplicate(to: Route, from: Route, next: Function) {
  if (to.fullPath === from.fullPath) {
    next(false)
    return
  }
  next()
}
