import { RouteConfig } from 'vue-router/types'

import { MeNames, MyCompanyNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const Me = () => import(/* webpackChunkName: "me" */ '@/pages/Me.vue')
const MyCompany = () => import(/* webpackChunkName: "me" */ '@/pages/MyCompany.vue')

const routes: RouteConfig[] = [
  {
    path: MeNames.path,
    component: Wrapper,

    children: [
      {
        path: ':section?',
        name: MeNames.OVERVIEW,
        component: Me,
        props: ({ query, params }) => ({ ...query, ...params }),
      },
    ],
  },

  {
    path: MyCompanyNames.path,
    component: Wrapper,

    children: [
      {
        path: ':section?',
        name: MyCompanyNames.OVERVIEW,
        component: MyCompany,
        props: ({ query, params }) => ({ ...query, ...params }),
      },
    ],
  },
]

export default routes
