/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

const module: Module<RootState['Counter'], RootState> = {
  namespaced: true,

  state: {
    value: 0,
  },

  getters: {},

  actions: {},

  mutations: {},

  modules: {},
}

export default module
