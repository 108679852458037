





























import {
  toRefs,
  onMounted,
  defineComponent,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

// Localization
import { useI18n } from '@/i18n'

// Router
import { useRouter, Routes } from '@/router'

import { useStore } from '@/store'
import { MODULES, ACTIONS } from '@/store/types'

import { RegistrationProvider } from '@/providers'
import { Authorization } from '@/services'
import { Payload } from '@/types/store'
import { AuthResult } from '@/types/api'
import { processStoreActions } from '@/store/utils'

const registrationProvider = new RegistrationProvider()

interface Props extends Record<string, any> {
  readonly token: string
}

export default defineComponent<Props>({
  components: {},

  props: {
    token: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { token } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()
    const store = useStore()
    const router = useRouter()

    async function processEmailConfirmation() {
      if (!token.value) {
        router.push({ name: Routes.of.Auth.LOGIN })
        return
      }

      if (process.env.NODE_ENV === 'development') {
        console.log({ token: token.value })
      }

      try {
        const { id, token: authToken, refresh_token } = await registrationProvider.confirmEmail({ token: token.value })

        if (!authToken) { throw new Error('no authToken') }

        Authorization.setToken(authToken)
        Authorization.setMetaData({ id })
        Authorization.setRefreshToken(refresh_token)

        await store.dispatch<Payload<AuthResult>>({
          type: `${MODULES.Auth}/${ACTIONS.Auth.setAuthState}`,
          id,
          token: authToken,
          refresh_token,
        })

        store.dispatch({
          type: `${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`,
        }).then(() => processStoreActions([
          `${MODULES.Conversations}/${ACTIONS.Conversations.createSubscription}`,
          `${MODULES.Notifications}/${ACTIONS.Notifications.createSubscription}`,
          `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
        ]))

        router.replace({ query: { u: id.toString() } })
      } catch (error) {
        toast.error(i18n.t(error))
      }
    }

    onMounted(processEmailConfirmation)

    return {}
  },
})

