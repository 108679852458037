import Vue from 'vue'
import CompositionApi, { computed, provide } from '@vue/composition-api'
import CurrencyInput from 'vue-currency-input'

import { provideToast } from 'vue-toastification/composition'

// @ts-ignore
import TheMask from 'vue-the-mask'
import Portal from 'portal-vue'

import 'vue-toastification/dist/index.css'

import './shims/array'

import store from './store'
import { ACTIONS, MODULES } from './store/types'

import router, { Routes } from './router'

import i18n, { loadLanguageAsync } from './i18n'

import App from './App.vue'

import vuetify, { useVuetify } from './plugins/vuetify'

import { Authorization } from './services/authorization'
import { Events } from './services/event'

import { POSITION } from './types/enums'

import { processStoreActions } from './store/utils'

import './registerServiceWorker'

Vue.use(TheMask)
Vue.use(Portal)
Vue.use(CompositionApi)
Vue.use(
  CurrencyInput,
  {
    globalOptions: {
      currency: 'RUB',
    },
  },
)

if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
  console.log('🎉 Dark mode is supported')
}

Vue.config.productionTip = false
Vue.config.devtools = true

async function init() {
  await loadLanguageAsync(window.navigator.language.slice(0, 2).toLowerCase())

  // toggle to refreshToken
  const auth = await Authorization.verifyToken()

  const application = new Vue({
    i18n,
    store,
    router,
    vuetify,
    setup() {
      const { breakpoint } = useVuetify()

      const isNotMobile = computed(() => breakpoint.smAndUp)
      const isTablet = computed(() => breakpoint.mdOnly)
      const isNotTablet = computed(() => breakpoint.lgAndUp)

      if (auth) {
        store.dispatch({
          type: `${MODULES.Auth}/${ACTIONS.Auth.setAuthState}`,
          ...auth,
        })

        store.dispatch({
          type: `${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`,
        }).then(() => processStoreActions([
          `${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`,
          `${MODULES.Conversations}/${ACTIONS.Conversations.createSubscription}`,
          `${MODULES.Notifications}/${ACTIONS.Notifications.createSubscription}`,
          `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
        ]))
      }

      provide('isNotMobile', isNotMobile)
      provide('isTablet', isTablet)
      provide('isNotTablet', isNotTablet)
      provideToast({ timeout: 5000, position: POSITION.BOTTOM_RIGHT })

      Events.on('unauthorized', async () => {
        store.dispatch(`${MODULES.Auth}/${ACTIONS.Auth.processSignOut}`)
        router.replace({ name: Routes.of.Auth.LOGIN })
      })

      return {}
    },
    render: h => h(App),
  })

  router.onReady(async () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('ROUTER READY')

      console.table({
        NODE_ENV: process.env.NODE_ENV,
        VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
        VUE_APP_TRANSPORT_WS: process.env.VUE_APP_TRANSPORT_WS,
      })
    }

    application.$mount('#wrapper')
  })
}

init()
