import {
  ListWithMetaData,
  Offer,
  OfferCreateInput,
  Order,
  OrderByParams,
  OrderCreateInput,
  OrdersOrderByParams,
  OrdersParams,
  OrderUpdateInput,
  User,
} from '@/types/api'

import { logApiRequest } from '@/services'

import { FormDataFromMap } from '@/utils/files'

import {
  BaseRestProvider,
  GET, POST, PUT,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  create: {
    path: '',
  },
  update: {
    path: (id: string | number) => `/${id}`,
  },
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  makeOffer: {
    path: (id: string | number) => `/${id}/offers`,
  },
  applyOffer: {
    path: (id: string | number) => `/${id}`,
  },
  getOffers: {
    path: (id: string | number) => `/${id}/offers`,
  },
  getContractors: {
    path: (id: string | number) => `/${id}/contractors`,
  },
  getList: {
    path: '',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class OrdersProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/orders`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * create - create an order
   */
  public async create(order: OrderCreateInput, options?: RequestConfig): Promise<Order> {
    const form = FormDataFromMap({ order })
    // for (let value of form.values()) {
    //   console.log(value)
    // }

    try {
      const { data } = await super[POST]<{ order: Order }>(routes.create.path, form, options)
      logApiRequest('OrdersProvider/create', order, data)
      return data.order
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/create', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }

  /**
   * makeOffer - attach offer to an order
   */
  public async makeOffer({ id }: OrdersParams, offer: OfferCreateInput, options?: RequestConfig): Promise<Offer> {
    try {
      const formData: any = {}
      if (offer.description) {
        formData['offer[description]'] = offer.description
      }
      if (offer.files) {
        formData['offer[files]'] = offer.files
      }
      formData['offer[price]'] = offer.price
      console.log(offer)
      const form = FormDataFromMap(formData)

      console.log(form)
      const { data } = await super[POST]<{ offer: Offer }>(routes.makeOffer.path(id), form, options)
      logApiRequest('OrdersProvider/makeOffer', form, data)
      return data.offer
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/makeOffer', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }

  /**
   * makeOffer - attach offer to an order
   */
  public async applyOffer({ id }: OrdersParams, order: OrderUpdateInput, options?: RequestConfig): Promise<Order> {
    try {
      const { data } = await super[PUT]<{ order: Order }>(routes.applyOffer.path(id), { order }, options)
      logApiRequest('OrdersProvider/applyOffer', order, data)
      return data.order
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/makeOffer', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }

  /**
   * getOffers - get order offers
   */
  public async getOffers({ id }: OrdersParams, options?: RequestConfig): Promise<ListWithMetaData<Offer>> {
    try {
      const { data } = await super[GET]<ListWithMetaData<Offer>>(routes.getOffers.path(id), options)
      logApiRequest('OrdersProvider/getOffers', { id }, data)
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/getOffers', { config: error.config, data: error.data }) }
      throw error.data
    }
  }

  /**
   * getContractors - get order Contractors
   */
  public async getContractors({ id }: OrdersParams, options?: RequestConfig): Promise<ListWithMetaData<User>> {
    try {
      const { data } = await super[GET]<ListWithMetaData<User>>(routes.getContractors.path(id), options)
      logApiRequest('OrdersProvider/getContractors', { id }, data)
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/getContractors', { config: error.config, data: error.data }) }
      throw error.data
    }
  }

  /**
   * update - update an order
   */
  public async update({ id }: OrdersParams, order: any, options?: RequestConfig): Promise<Order> {
    // const form = FormDataFromMap({ order })

    try {
      const { data } = await super[PUT]<{ order: Order }>(routes.update.path(id), order, options)
      logApiRequest('OrdersProvider/update', order, data)
      return data.order
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/update', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }

  /**
   * getOne - get one order
   */
  public async getOne(params: OrdersParams, options?: RequestConfig): Promise<Order> {
    const url = routes.getOne.path(params.id)

    try {
      const { data } = await super[GET]<{ order: Order }>(url, options)
      logApiRequest('OrdersProvider/getOne', params, data)
      return data.order
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/getOne', { config: error.config, data: error.data }) }
      throw error.data
    }
  }

  /**
   * getList - get order collection
   */
  public async getList(params: OrdersOrderByParams, orderBy: OrderByParams, page: number, search: string, options?: RequestConfig): Promise<ListWithMetaData<Order>> {
    const orderByTransformed = this.transformOrderBy(orderBy)
    let url

    if (search) url = this.getUrlWithParams(routes.getList.path, [...orderByTransformed, `page=${page}`, `search=${search}`])
    else url = this.getUrlWithParams(routes.getList.path, [...orderByTransformed, `page=${page}`])

    try {
      const { data } = await super[GET]<ListWithMetaData<Order>>(url, options)
      logApiRequest('OrdersProvider/getList', params, data)
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/getList', { config: error.config, data: error.data }) }
      throw error.data
    }
  }

  protected getUrlWithParams(url: string, params: any) {
    const query = this.stringifyOrderByQuery(params)
    const result = [url, query].join('?')
    return query.length > 1 ? result : url
  }

  // eslint-disable-next-line class-methods-use-this
  protected stringifyOrderByQuery(array: string[]): string {
    let queryParams = ''
    array.forEach((elem, index) => {
      if (index !== array.length - 1) {
        queryParams += `${elem}&`
      } else {
        queryParams += elem
      }
    })
    return queryParams
  }

  // eslint-disable-next-line class-methods-use-this
  protected transformOrderBy(params: OrderByParams) {
    const transformed = Object.entries(params)
    const transformedOrderBy: string[] = []
    transformed.forEach(val => {
      transformedOrderBy.push(`sort[]=${val[0]}=${val[1]}`)
    })
    return transformedOrderBy
  }
}

export const ordersProvider = new OrdersProvider()
