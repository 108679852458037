/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-constructor */
import { AuthPayload, AuthResult } from '@/types/api'

import { AuthProvider } from '@/providers'
import { StorageProvider, LocalStorage } from './storage'

interface SbbolRedirectParams {
  code: string
  nonce: string
  state: string
}

const metaDataKeys = [
  'email', 'refresh_token', 'id',
]

class Auth {
  private provider: AuthProvider;

  private localStorageProvider: StorageProvider;

  // private sessionStorageProvider: StorageProvider;

  private token: string;

  private refreshTokenValue: string;

  private clientId: string;

  private meta: Record<string, any>;

  public constructor(
    private tokenField: string,
  ) {
    this.provider = new AuthProvider()
    this.localStorageProvider = LocalStorage
    // this.sessionStorageProvider = SessionStorage
    this.token = this.localStorageProvider.get(this.tokenField) || ''
    this.clientId = this.localStorageProvider.get('clientId') || ''
    this.refreshTokenValue = this.localStorageProvider.get('refreshToken') || ''
    this.meta = this.getMetaData()
  }

  /**
   * isLoggedIn
   */
  public isLoggedIn(): boolean {
    return this.localStorageProvider.contains(this.tokenField)
  }

  /**
   * getToken
   */
  public getTokens() {
    const bearer = this.localStorageProvider.get(this.tokenField) || this.token
    const refresh = this.getRefreshToken()

    return { bearer, refresh }
  }

  /**
   * setToken
   */
  public setToken(value: string) {
    this.token = value
    this.localStorageProvider.set(this.tokenField, value)
  }

  /**
   * getClientId
   */
  public getClientId(): string {
    return this.localStorageProvider.get('clientId') || this.clientId
  }

  /**
   * setClientId
   */
  public setClientId(value: string) {
    this.clientId = value
    return this.localStorageProvider.set('clientId', value)
  }

  /**
   * getRefreshToken
   */
  public getRefreshToken(): string {
    return this.localStorageProvider.get('refreshToken') || this.refreshTokenValue
  }

  /**
   * setToken
   */
  public setRefreshToken(value: string) {
    this.refreshTokenValue = value
    return this.localStorageProvider.set('refreshToken', value)
  }

  /**
   * setMetaData
   */
  public setMetaData(meta: Record<string, any>) {
    this.meta = Object.assign(this.meta, meta)
    for (const key in meta) {
      if (Object.prototype.hasOwnProperty.call(meta, key)) {
        const value = meta[key]
        this.localStorageProvider.set(key, value)
      }
    }
  }

  /**
   * getMetaData
   */
  public getMetaData() {
    const result: Record<string, any> = {}
    for (const key of metaDataKeys) {
      result[key] = this.localStorageProvider.get(key)
    }
    return result
  }

  /**
   * getBearer
   */
  public getBearer() {
    return `Bearer ${this.getTokens().bearer}`
  }

  /**
   * processRefreshToken
   */
  public async processRefreshToken() {
    const refreshToken = this.getRefreshToken()

    console.log('Auth/processRefreshToken')

    try {
      const { token, refresh_token } = await this.provider.refreshToken(refreshToken)
      this.setToken(token)
      this.setRefreshToken(refresh_token)
      return { token, refreshToken: refresh_token }
    } catch (error) {
      console.error('Auth/processRefreshToken', error)
      throw error
    }
  }

  /**
   * processSignIn
   */
  // eslint-disable-next-line
  public async processSignIn(payload: AuthPayload, options?: Record<string, any>): Promise<AuthResult> {
    console.log('Auth/processSignIn')

    try {
      const { id, token, refresh_token } = await this.provider.signIn(payload)

      if (!token || !refresh_token) { throw new Error('NO_TOKEN') }

      this.setToken(token)
      this.setMetaData({ email: payload.user.email, id })
      this.setRefreshToken(refresh_token)

      return { token, refresh_token, id }
    } catch (error) {
      console.error('Auth/processSignIn', error)
      throw error
    }
  }

  /**
   * processSignIn
   */
  public async signInWithSberBusinessId(params?: SbbolRedirectParams) {
    console.log('Auth/signInWithSberBusinessId', { params })

    try {
      const result = await this.provider.signInWithSberBusinessId(params)

      if (result.token && result.refresh_token) {
        const { id, token, refresh_token } = result
        this.setToken(token)
        this.setMetaData({ id })
        this.setRefreshToken(refresh_token)
      }
      return result
    } catch (error) {
      console.error('Auth/signInWithSberBusinessId', error)
      throw error
    }
  }

  /**
   * verifyToken
   */
  public async verifyToken() {
    const { token, meta } = this

    if (!token.length) { return null }

    try {
      // const response = await this.provider.verifyToken({ token });
      // return response.token;
      return { token, ...meta }
    } catch (error) {
      console.error(error)
      this.localStorageProvider.remove(this.tokenField)
      return null
    }
  }

  /**
   * refreshToken
  //  */
  // public async refreshToken() {
  //   const refreshTokenValue = this.getRefreshToken()

  //   if (!refreshTokenValue.length) { return null }

  //   // try {
  //   //   const result = await this.provider.refreshToken(refreshTokenValue);

  //   //   console.log({ result });

  //   // this.setToken(data?.refreshToken.accessToken?.token ?? '');
  //   // this.setClientId(data?.refreshToken.refreshToken?.clientId ?? '');
  //   // this.setRefreshToken(data?.refreshToken.refreshToken?.token ?? '');

  //   // return data?.refreshToken;

  //   //   return result;
  //   // } catch (error) {
  //   //   console.error(error);
  //   // this.processSignOut();
  //   return null
  //   // }
  // }

  /**
   * signOut
   */
  public processSignOut() {
    this.meta = {}
    this.token = ''
    this.localStorageProvider.remove(this.tokenField)
    this.localStorageProvider.clear()
    console.clear()
  }
}

export const Authorization = new Auth('token')
