import Auth from './Auth'
import Companies from './Companies'
import Conversations from './Conversations'
import Counter from './Counter'
import Interface from './Interface'
import Notifications from './Notifications'
import Orders from './Orders'
import Profile from './Profile'
import Users from './Users'
import Version from './version'
import Reviews from './Reviews'
import Marketplace from './Marketplace'

export default {
  Auth,
  Companies,
  Conversations,
  Counter,
  Interface,
  Notifications,
  Orders,
  Profile,
  Users,
  Version,
  Reviews,
  Marketplace,
}
