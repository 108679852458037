export enum AUTH {
  SET_AUTH_DATA = 'SET_AUTH_DATA',
  SET_USER_DATA = 'SET_USER_DATA',
  SIGN_OUT = 'SIGN_OUT',
}

export enum INTERFACE {
  SET_DRAWER = 'SET_DRAWER'
}

export enum ORDERS {
  SET_LIST = 'SET_LIST',
  ADD_TO_LIST = 'ADD_TO_LIST',
  SET_PAGE = 'SET_PAGE',
  CLEAR_LIST = 'CLEAR_LIST'
}

export enum PROFILE {
  SET_USER = 'SET_USER',
  SET_MY_COMPANY = 'SET_MY_COMPANY'
}

export enum REVIEWS {
  SET_LIST = 'SET_LIST',
  ADD_TO_LIST = 'ADD_TO_LIST'
}

export enum MARKETPLACE {
  CLEAR_MARKETPLACE_LIST = 'CLEAR_MARKETPLACE_LIST',
  ADD_MARKETPLACE_ITEM = 'ADD_MARKETPLACE_ITEM'
}

export enum COMPANIES {
}

export enum NOTIFICATIONS {
  ADD_NOTIFICATION_ITEM = 'ADD_NOTIFICATION_ITEM',
  REMOVE_NOTIFICATION_ITEM = 'REMOVE_NOTIFICATION_ITEM'
}

export enum CONVERSATIONS {
  ADD_CONVERSATION_ITEM = 'ADD_CONVERSATION_ITEM',
  ADD_ORDER_MESSAGE = 'ADD_ORDER_MESSAGE',
  ADD_CONVERSATION_MESSAGE = 'ADD_CONVERSATION_MESSAGE',
  UNSHIFT_CONVERSATION_MESSAGE = 'UNSHIFT_CONVERSATION_MESSAGE',
  SET_LAST_SERVER_N_TIME = 'SET_LAST_SERVER_N_TIME',
  ADD_COMPLETED_QUERY = 'ADD_COMPLETED_QUERY',
  SET_LAST_CONVERSATION_MESSAGE_IF_UNSENT = 'SET_LAST_CONVERSATION_MESSAGE_IF_UNSENT',
  CLEAR_CONVERSATIONS_LIST = 'CLEAR_CONVERSATIONS_LIST',
  SET_CONVERSATION_LIST = 'SET_CONVERSATION_LIST'
}
