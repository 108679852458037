




















































// packages
import {
  ref,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'

// @ts-ignore
import { mask } from 'vue-the-mask'
import { useToast } from 'vue-toastification/composition'

import { createMachine } from '@xstate/fsm'

// Typings
import { CompanyCreateInput, AvailableCompanyType } from '@/types/api'

// Localization
import { useI18n } from '@/i18n'

import { useStore } from '@/store'
import { MODULES, ACTIONS } from '@/store/types'

// Router
import { useRouter, Routes } from '@/router'

import { useMachine } from '@/utils/state-machine'

import { companiesRepository } from '@/repositories'

// Utils
import {
  rules,
  validateForm,
} from '@/utils/validators'
import { VForm } from '@/types/vuetify'

interface Props extends Record<string, any> {
  readonly role: AvailableCompanyType
}

const machine = createMachine({
  id: 'auth-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent<Props>({
  directives: {
    mask,
  },

  props: {
    role: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { role } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()
    const store = useStore()
    const router = useRouter()

    const showPassword = ref(false)

    const formRef = ref<VForm>()

    const errors = ref({})

    const showConfirmationDialog = ref(false)

    const state = reactive<{ company: CompanyCreateInput }>({
      company: {
        inn: '',
        kind: role.value,
      },
    })

    const { state: loadingState, send } = useMachine(machine)

    const loading = computed(() => loadingState.value.matches('active'))

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        send('TOGGLE')

        const result = await companiesRepository.create(state.company)
        if (!result.id) { throw new Error('no company id in response') }

        store.dispatch({
          type: `${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`,
        })

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        toast.error(error[0])
      } finally {
        send('TOGGLE')
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.company_created'))
      router.replace({ name: Routes.of.MyCompany.OVERVIEW })
    }

    return {
      state,
      formRef,
      errors,
      rules,
      onSubmit,
      showPassword,
      loading,

      showConfirmationDialog,
    }
  },
})

