/* eslint-disable no-param-reassign */
import Vue from 'vue'
import I18n from 'vue-i18n'
import { getCurrentInstance } from 'vue-demi'

import { setProviderLanguage } from '@/providers/base'

Vue.use(I18n)

/**
 * @name getChoiceIndex
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
* */
I18n.prototype.getChoiceIndex = function getChoiceIndex(choice: number, choicesLength: number) {
  // this === I18n instance, so the locale property also exists here
  if (this.locale !== 'ru') {
    choice = Math.abs(choice)

    if (choicesLength === 2) {
      if (choice) {
        return choice > 1 ? 1 : 0
      }
      return 1
    }

    return choice ? Math.min(choice, 2) : 0
  }

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 3
  }

  return (choicesLength < 4) ? 2 : 3
}

const i18n = new I18n({
  locale: 'ru',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {},
  dateTimeFormats: {
    'ru-RU': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    'ru-RU': {
      currency: {
        style: 'currency',
        currency: 'RUB',
      },
    },
  },
})

const loadedLanguages: string[] = []

function setI18nLanguage(lang: string) {
  i18n.locale = lang
  setProviderLanguage(lang)
  const $html = document.querySelector('html')
  if ($html) { $html.setAttribute('lang', lang) }
  return lang
}

export async function loadLanguageAsync(locale: string) {
  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale))
  }
  // If the language hasn't been loaded yet
  const message = await import(/* webpackChunkName: "[request]" */ `./${locale}.json`)

  i18n.setLocaleMessage(locale, message.default)
  loadedLanguages.push(locale)
  return setI18nLanguage(locale)
}

export function useStaticI18n() {
  return i18n
}

export function $t(key: I18n.Path) {
  return useStaticI18n().t(key)
}

// should be deprecate when use Vue 3
export function useI18n() {
  const vm = getCurrentInstance()?.proxy
  if (!vm) {
    throw new ReferenceError(
      'Vue instance not found.\n'
      + 'It looks like you trying to useI18n() outside setup() function',
    )
  }
  return vm.$i18n
}

export default i18n
