import { logApiRequest } from '@/services'

import {
  BaseRestProvider,
  POST,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {

  update: {
    path: '/update_notification_dt/',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class NotificationsProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/users`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  public async update() {
    try {
      const { data } = await super[POST](routes.update.path)
      logApiRequest('OrdersProvider/update')
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('OrdersProvider/update', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }
}

export const notificationsProvider = new NotificationsProvider()
