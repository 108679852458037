// eslint-disable-next-line import/prefer-default-export
export function onKeyUpEscape(cb: (event: KeyboardEvent) => void) {
  document.addEventListener('keyup', (event: KeyboardEvent) => {
    if (!event.key) { return }

    const key = event.key.toLowerCase()
    const isEscape = key === 'escape'

    if (isEscape) { cb(event) }
  })
}
