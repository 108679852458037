/* eslint-disable import/prefer-default-export */
import { createNameSpace } from '@/utils/helpers'
import { DEFAULT_OVERVIEW_NAME } from './constants'

export abstract class MeNames {
  public static get path() {
    return '/me'
  }

  private static get nameSpace() {
    return createNameSpace('Me')
  }

  public static get OVERVIEW() {
    return this.nameSpace.with(DEFAULT_OVERVIEW_NAME)
  }

  public static get CERTIFICATION() {
    return this.nameSpace.with('certification')
  }

  public static get WORKS() {
    return this.nameSpace.with('works')
  }

  // eslint-disable-next-line class-methods-use-this
  public static toJSON(): Record<string, any> {
    return {
      namespace: MeNames.nameSpace,
      overview: MeNames.OVERVIEW,
      certification: MeNames.CERTIFICATION,
      works: MeNames.WORKS,
    }
  }
}
