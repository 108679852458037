import { CompaniesProvider } from '@/providers'

import {
  Company,
  CompanyCreateInput,
  CompanyUpdateInput,
  CompanyWhereInput,
} from '@/types/api'
import { FormDataFromMap } from '@/utils/files'

import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class CompaniesRepository implements DefaultCrudRepository<
Company,
CompanyCreateInput,
Partial<CompanyUpdateInput>,
Array<Partial<CompanyUpdateInput>>,
any,
any,
any
> {
  private provider: CompaniesProvider;

  public constructor() {
    this.provider = new CompaniesProvider()
  }

  public create(payload: CompanyCreateInput): Promise<Company> {
    return this.provider.create(payload)
  }

  public createMany(data: any[]): Promise<any[]> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public save(data: any): Promise<any> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public find(where: { id: string | number }): Promise<Company> {
    return this.provider.getOne(where, {})
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(where: any, work_id?: number | string, search?: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<any[]> {
    const { results } = await this.provider.getList(where, {})
    return results
  }

  public update(where: CompanyWhereInput, company: Partial<CompanyUpdateInput> | FormData): Promise<Company> {
    const isFormData = company instanceof FormData

    const form = !isFormData ? FormDataFromMap({ company }) : company
    return this.provider.update(where, form)
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<any> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }
}

export const companiesRepository = new CompaniesRepository()
