






























































































import {
  ref,
  computed,
  defineComponent,
  nextTick,
} from 'vue-demi'

import { useStore } from '@/store'
import { useRouter } from '@/router'
import { MODULES, ACTIONS } from '@/store/types'

import { useI18n } from '@/i18n'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()

    const i18n = useI18n()

    const openedMenu = ref(false)

    const loading = ref<boolean>(false)

    const notifications = computed<string[]>(() => store.state.Notifications.list)

    const profile = computed(() => store.state.Profile.user)

    function onNotificationClick(item: any) {
      router.push(item.link)
    }

    function onCloseNotificationClick() {
      openedMenu.value = false
      nextTick(() => store.dispatch(
        `${MODULES.Notifications}/${ACTIONS.Notifications.removeNotificationItem}`,
      ))
    }

    function getDateFromCreatedAt(createdAt: string) {
      const minimalDate = new Date(createdAt)
      console.log(minimalDate)
      return `${(minimalDate.getDate() < 10) ? `0${minimalDate.getDate()}` : minimalDate.getDate()}.${
        (minimalDate.getMonth() + 1) < 10 ? `0${minimalDate.getMonth() + 1}` : minimalDate.getMonth() + 1}.${
        (minimalDate.getFullYear()) < 10 ? `0${minimalDate.getFullYear()}` : minimalDate.getFullYear()} ${
        (minimalDate.getHours()) < 10 ? `0${minimalDate.getHours()}` : minimalDate.getHours()}:${
        (minimalDate.getMinutes()) < 10 ? `0${minimalDate.getMinutes()}` : minimalDate.getMinutes()}`
    }

    return {
      loading,
      profile,
      notifications,
      openedMenu,

      getDateFromCreatedAt,

      onNotificationClick,
      onCloseNotificationClick,
      i18n,
    }
  },
})

