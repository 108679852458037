
















import {
  toRefs,
  onMounted,
  defineComponent,
} from 'vue-demi'

import { AuthResult } from '@/types/api'
import { Payload } from '@/types/store'

import { useStore } from '@/store'
import { ACTIONS, MODULES } from '@/store/types'
import { processStoreActions } from '@/store/utils'

import { useRouter } from '@/router'

import { Authorization } from '@/services/authorization'

interface SbbolRedirectParams {
  code: string
  nonce: string
  state: string
}

interface Props extends Record<string, any> {
  readonly code: string
  readonly nonce: string
  readonly state: string
}

export default defineComponent<Props>({
  props: {
    code: {
      type: String,
      required: true,
    },

    nonce: {
      type: String,
      required: true,
    },

    state: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { code, nonce, state } = toRefs(props)

    const store = useStore()
    const router = useRouter()

    async function signInWithSberBusinessId(params: SbbolRedirectParams) {
      const { id, token, refresh_token } = await Authorization.signInWithSberBusinessId(params)

      if (id && token && refresh_token) {
        await store.dispatch<Payload<AuthResult>>({
          type: `${MODULES.Auth}/${ACTIONS.Auth.setAuthState}`,
          id,
          token,
          refresh_token,
        })

        store.dispatch({
          type: `${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`,
        }).then(() => processStoreActions([
          `${MODULES.Conversations}/${ACTIONS.Conversations.createSubscription}`,
          `${MODULES.Notifications}/${ACTIONS.Notifications.createSubscription}`,
          `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
        ]))
      }

      router.replace({ name: 'Main' })
    }

    onMounted(() => signInWithSberBusinessId({ code: code.value, nonce: nonce.value, state: state.value }))

    return {
      signInWithSberBusinessId,
    }
  },
})

