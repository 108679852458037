/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { nanoid } from 'nanoid'

export interface CreateNameSpaceType {
  with(name: string): string
}

/**
 * Get flatten errors object
 */
export function getFlatErrors(source: any[] = []) {
  const flatErrors = (errors: any[] = []) => errors.map(err => err.message).filter(err => err)
  const result: Record<string, any> = {}
  source.forEach(src => result[src.field] = flatErrors(src.errors))
  return result
}

export function createNameSpace(nameSpace: string): CreateNameSpaceType {
  const namespace = nameSpace

  return {
    with(name) {
      return name && name.length > 1
        ? `${namespace}/${name}`
        : namespace
    },
  }
}

/**
 * Get the raw type string of a value, e.g., [object Object] -> Object.
 * @param value
 */
export function toRawType(value: any) {
  return Object.prototype.toString.call(value).slice(8, -1)
}

/**
 * Strict object type check. Only returns true
 * for plain JavaScript objects.
 * @param obj
 */
export function isPlainObject(obj: any) {
  return toRawType(obj) === 'Object'
}

export function isArray(array: any[]) {
  return toString.call(array) === '[object Array]' && Array.isArray(array)
}

export function isRegExp(v: any) {
  return toRawType(v) === 'RegExp'
}

/**
 * Compose functions from right to left
 * @example pipe(reverse, capitalize, lowercase)
 * @param fns Function[]
 */
export function pipe(...fns: Function[]) {
  return (payload: any) => fns.reduce((y, f) => f(y), payload)
}

export function curry(fn: Function, ...args: any[]): any {
  return fn.length <= args.length ? fn(...args) : curry.bind(null, fn, ...args)
}

export function generateId(length = 16) {
  return nanoid(length)
}

export function sleeper(ms: number) {
  return new Promise(resolve => setTimeout(() => resolve(ms), ms))
}
