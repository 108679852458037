import { getCurrentInstance } from 'vue-demi'

import Vue from 'vue'
import Vuetify, { Framework } from 'vuetify'

import colors from 'vuetify/lib/util/colors'

import ru from 'vuetify/src/locale/ru'
import en from 'vuetify/src/locale/en'

import 'vuetify/dist/vuetify.min.css'

import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.use(Vuetify)

// should be deprecate when use Vue 3
export function useVuetify(): Framework {
  const vm = getCurrentInstance()?.proxy
  if (!vm) {
    throw new ReferenceError(
      'Vue instance not found.\n'
      + 'It looks like you trying to useVuetify() outside setup() function',
    )
  }
  return vm.$vuetify
}

export default new Vuetify({
  scrollBarWidth: 8,
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: { ru, en },
    current: 'ru',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#FC7554',
        secondary: '#212121',
        accent: '#27AE60',
        error: colors.red.base,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.yellow.base,
        indigo: '#433A5F',
      },
      dark: {
        primary: '#FC7554',
        secondary: '#424242',
        accent: '#27AE60',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
    },
  },
})
