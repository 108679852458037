/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState, SetDrawerPayload } from '@/types/store'

import { MUTATIONS } from '../types'

const module: Module<RootState['Interface'], RootState> = {
  namespaced: true,

  state: {
    drawers: {
      menu: false,
      sidebar: true,
      info: false,
    },
  },

  getters: {},

  actions: {},

  mutations: {
    [MUTATIONS.INTERFACE.SET_DRAWER](state, { key, value }: SetDrawerPayload) {
      state.drawers[key] = value
    },
  },

  modules: {},
}

export default module
