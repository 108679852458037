import {
  ListWithMetaData,
} from '@/types/api'

import { logApiRequest } from '@/services'

import {
  BaseRestProvider,
  GET,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  getList: {
    path: '',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class MarketplaceProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/marketplace`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  public async getList(params: any, page: number, work_id: number | string, search: string, options?: RequestConfig): Promise<ListWithMetaData<any[]>> {
    let url
    if (work_id && search) {
      url = this.getUrlWithParams(routes.getList.path, { page, work_id, search })
    } else if (work_id) {
      url = this.getUrlWithParams(routes.getList.path, { page, work_id })
    } else if (search) {
      url = this.getUrlWithParams(routes.getList.path, { page, search })
    } else {
      url = routes.getList.path
    }

    try {
      const { data } = await super[GET]<ListWithMetaData<any>>(url, options)
      logApiRequest('MarketplaceProvider/getList', params, data)
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('MarketplaceProvider/getList', { config: error.config, data: error.data }) }
      throw error.data
    }
  }
}
export const marketplaceProvider = new MarketplaceProvider()
