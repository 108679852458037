import { RouteConfig } from 'vue-router/types'

import { ServicesNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const Service = () => import(/* webpackChunkName: "services" */ '@/pages/Service.vue')
const List = () => import(/* webpackChunkName: "services" */ '@/pages/ServiceList.vue')

const routes: RouteConfig[] = [
  {
    path: ServicesNames.path,
    component: Wrapper,

    children: [
      {
        path: '',
        name: ServicesNames.LIST,
        component: List,
        props: ({ query }) => query,
      },

      {
        path: '',
        name: ServicesNames.CREATE,
        redirect: () => ({
          name: ServicesNames.LIST,
          query: { 'add-dialog': 'true' },
        }),
      },

      {
        path: ':customId/:section?',
        name: ServicesNames.OVERVIEW,
        component: Service,
        props: ({ query, params }) => ({ ...query, ...params }),
      },
    ],
  },
]

export default routes
