




















































































































































































































import {
  ref,
  computed,
  reactive,
  onMounted,
  defineComponent,
  nextTick,
} from 'vue-demi'
import { useToast } from 'vue-toastification/composition'

import { Work, WorkType, OrderCreateInput } from '@/types/api'
import { VForm } from '@/types/vuetify'

import { useI18n } from '@/i18n'
import { useStore } from '@/store'
import { Routes, useRouter } from '@/router'

import { rules, validateForm } from '@/utils/validators'
import { addValueToDateByDuration } from '@/utils/dates'

import { ordersRepository, worksRepository, workTypesRepository } from '@/repositories'

import VCurrencyField from '@/components/widgets/VCurrencyField.vue'
import DragAndDropZone from '@/components/widgets/DragAndDropZone.vue'
import DialogDatePicker from '@/components/widgets/DialogDatePicker.vue'
import AddressAutocomplete from '@/components/widgets/AddressAutocomplete.vue'

interface Props extends Record<string, any> {
  readonly foo: string
}

interface State {
  workList: Work[]
  workTypeList: WorkType[]
  selectedWorkId: number | null
}

function calcDate(date: string, hours: string, minutes: string) {
  return addValueToDateByDuration(date, { hours: Number(hours), minutes: Number(minutes) })
}

export default defineComponent<Props>({
  components: {
    VCurrencyField,
    DragAndDropZone,
    DialogDatePicker,
    AddressAutocomplete,
  },

  props: {
    selectedCompanies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const now = ref(new Date().toISOString())

    const i18n = useI18n()

    const store = useStore()
    const toast = useToast()
    const router = useRouter()

    const formRef = ref<VForm>()
    const initialValue = formRef.value

    const customerId = computed(() => store.state.Auth.id)
    const dropZone = ref()

    const state = reactive<State>({
      workList: [],
      workTypeList: [],
      selectedWorkId: -1,
    })

    const form = ref<OrderCreateInput>({
      title: '',
      address: '',
      description: '',
      phone_number: '',
      first_price: null,
      final_price: null,
      start_dt: now.value.substr(0, 10),
      end_dt: addValueToDateByDuration(now.value, { days: 7 }).substr(0, 10),
      trade_deadline: '',
      work_id: 0,
      contract_id: customerId.value || 0,
      images: [],
    })

    const startDate = ref(now.value.substr(0, 10))
    const endDate = ref(addValueToDateByDuration(now.value, { days: 7 }).substr(0, 10))

    const startTime = ref('14:00')
    const endTime = ref('12:00')

    const startDateTime = computed(() => {
      const [hours, minutes] = startTime.value.split(':')
      try {
        return calcDate(startDate.value, hours, minutes)
      } catch (error) {
        return new Date().toISOString()
      }
    })

    const endDateTime = computed(() => {
      const [hours, minutes] = endTime.value.split(':')
      try {
        return calcDate(endDate.value, hours, minutes)
      } catch (error) {
        return new Date().toISOString()
      }
    })

    async function fetchWorkList() {
      state.workList = await worksRepository.findMany({})
    }

    async function fetchWorkTypeList() {
      state.workTypeList = await workTypesRepository.findMany({})
    }

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      try {
        console.log(form.value)
        nextTick(async () => {
          console.log(form.value)
          const { id } = await ordersRepository.create(form.value)
          onSuccessSubmit()
          router.push({ name: Routes.of.Orders.OVERVIEW, params: { id: id.toString() } })
        })
      } catch (error) {
        toast.warning(i18n.t('ui.errors.error_create_order'))
      }
    }

    function resetDialog() {
      form.value = {
        title: '',
        address: '',
        description: '',
        phone_number: '',
        first_price: null,
        final_price: null,
        start_dt: now.value.substr(0, 10),
        end_dt: addValueToDateByDuration(now.value, { days: 7 }).substr(0, 10),
        trade_deadline: '',
        work_id: 0,
        contract_id: customerId.value || 0,
        images: [],
      }
      formRef.value = initialValue
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.order_created'))

      resetDialog()

      emit('close')
      emit('success')
    }

    onMounted(async () => {
      await fetchWorkList()
      await fetchWorkTypeList()
      if (props.selectedCompanies.length) {
        form.value.company_ids = props.selectedCompanies.map((elem: any) => elem.id)
        form.value.work_id = props.selectedCompanies[0].work_id
        state.selectedWorkId = state.workList.filter(elem => elem.id === form.value.work_id)[0].work_type_id
      }
    })

    return {
      rules,
      formRef,
      form,
      state,
      startDate,
      endDate,
      startTime,
      endTime,
      startDateTime,
      endDateTime,
      onSubmit,
      dropZone,
    }
  },
})

