/* eslint-disable import/prefer-default-export */

import {
  BaseRestProvider,
  GET, POST,
} from './base'

class Example extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_URL}/example`,
    )
  }

  public getExampleStatus(exampleKey: string) {
    return super[GET]<string>(`/${exampleKey}`)
  }

  public updateExampleStatus(exampleKey: string, value: boolean) {
    const payload: Record<string, any> = {
      value: value ? 'on' : 'off',
    }

    return super[POST]<string>(`/${exampleKey}`, payload)
  }
}

export const ExampleProvider = new Example()
