/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

import { socketProvider } from '@/providers/sockets'

import { Authorization } from '@/services/authorization'

import { notificationsProvider } from '@/providers/notifications'

import { ACTIONS, MUTATIONS } from '../types'

const module: Module<RootState['Notifications'], RootState> = {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {},

  actions: {
    [ACTIONS.Notifications.createSubscription]({ dispatch }) {
      socketProvider.createSubscription({
        channel: 'NotificationsChannel',
        params: {
          token: Authorization.getTokens().bearer,
        },
        received: message => dispatch(ACTIONS.Notifications.processSubscriptionReceivedData, message),
        connected: () => { console.warn('NotificationsChannel connected') },
        disconnected: () => { console.warn('NotificationsChannel disconnected') },
      })
    },

    [ACTIONS.Notifications.processSubscriptionReceivedData]({ commit }, message?: any) {
      if (!message) { return }

      if (!message.topic) {
        message.forEach((elem: any) => (elem.topic === '#notification' ? commit(MUTATIONS.NOTIFICATIONS.ADD_NOTIFICATION_ITEM, elem.data) : console.log('unknown message topic', elem)))
      } else {
        switch (message.topic) {
          case '#notification':
            commit(MUTATIONS.NOTIFICATIONS.ADD_NOTIFICATION_ITEM, message.data)
            break

          default:
            console.log('unknown message topic', message)
            break
        }
      }
      // messages.forEach(message => {

      // })
    },

    async [ACTIONS.Notifications.removeNotificationItem]({ commit }) {
      await notificationsProvider.update()
      commit(MUTATIONS.NOTIFICATIONS.REMOVE_NOTIFICATION_ITEM)
    },
  },

  mutations: {
    [MUTATIONS.NOTIFICATIONS.ADD_NOTIFICATION_ITEM](state, message: any) {
      console.log(message)
      let exist = false
      state.list.forEach(elem => (elem.id === message.id ? exist = true : false))
      if (!exist) {
        state.list.push(message)
      }
    },

    [MUTATIONS.NOTIFICATIONS.REMOVE_NOTIFICATION_ITEM](state) {
      state.list = []
    },
  },

  modules: {},
}

export default module
