// `encodeURIComponent` doesn't encode -_.!~*'()
export function encode(url: string) {
  return encodeURIComponent(url)
    .replace(/!/g, '%21')
    .replace(/~/g, '%7E')
    .replace(/\*/g, '%2A')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/%20/g, '+')
}

export function extractDigits(value: string) {
  return value.replace(/\D/gm, '')
}

export function getRandomInt(length: number) {
  return Math.random().toString().substr(2, length)
}

export function decodeJWT<T = any>(token: string): T {
  const result = decodeURIComponent(
    atob(token.split('.')[1]
      .replace('-', '+')
      .replace('_', '/'))
      .split('')
      .map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
      .join(''),
  )
  return JSON.parse(result)
}
