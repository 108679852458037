import { Module } from 'vuex/types'

import { RootState } from '@/types/store'

const module: Module<RootState['Companies'], RootState> = {
  namespaced: true,

  state: {
  },

  getters: {
  },

  actions: {
  },

  mutations: {
  },

  modules: {},
}

export default module
