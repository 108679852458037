/* eslint-disable import/prefer-default-export */

// * @AlexQuidditch
// для обновления объектов в хранилищах
// должен содержать список объектов для обновления
// тип, запрос, интервал
// по интервалу отправлять событие вызова обновления в EventEmitter
// в нужном месте реализовать логику обновления необходимых данных при получении события

import { EventEmitter, Events } from '@/services'

class Scheduler {
  // TODO: определить интерфейс Task
  private tasks: any[]

  public constructor(
    private emitter: EventEmitter,
  ) {
    this.tasks = []
  }
}

export const scheduler = new Scheduler(Events)
