/* eslint-disable import/prefer-default-export */

function getMessageFromEnv(value: any): string | any {
  return JSON.stringify(value)
}

/**
 * * Logs API request
 * @param {string} name - имя запроса
 * @param {any} params - параметры запроса
 * @param {any} response - ответ
 * @param {any} errors - ошибки
 */
export function logApiRequest(name: string, params?: any, response?: any): void {
  if (process.env.NODE_ENV === 'production') return
  console.groupCollapsed('%c API Request ', 'color: white; background-color: #2274A5', getMessageFromEnv(name))
  console.log('params:\n')
  console.dir(getMessageFromEnv(params))
  console.log('response:\n')
  console.dir(getMessageFromEnv(response))
  console.groupEnd()
}
