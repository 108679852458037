/* eslint-disable import/prefer-default-export */

import { WorksProvider, WorkTypesProvider } from '@/providers'

import { WorkType, WorkTypesParams } from '@/types/api'
import { WorkTypeWithWorks } from '@/types/extended'

// @ts-ignore
import { v4 as uuidv4 } from 'uuid'

import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class WorkTypesRepository implements DefaultCrudRepository<
WorkType,
any,
any,
any,
any,
any,
any
> {
  private provider: WorkTypesProvider;

  private worksProvider: WorksProvider;

  public constructor() {
    this.provider = new WorkTypesProvider()
    this.worksProvider = new WorksProvider()
  }

  public create(data: any): Promise<any> {
    this.provider.getList(data, {})
    throw new Error('Method not implemented.')
  }

  public createMany(data: any[]): Promise<any[]> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public save(data: any): Promise<any> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public find(where: { id: number }) {
    return this.provider.getOne(where, {})
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(where: WorkTypesParams, work_id?: number | string, orderBy?: any, search?: any, options?: EntityWhereManyInputOptions) {
    const { results } = await this.provider.getList(where, {})
    return results
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findManyWithWorks(where: WorkTypesParams, orderBy?: any, options?: EntityWhereManyInputOptions) {
    const workTypesList = await this.findMany(where)
    const requests = workTypesList.map<Promise<WorkTypeWithWorks>>(async workType => {
      const { results } = await this.worksProvider.getList({ work_type_id: workType.id })

      const key = uuidv4()

      return {
        ...workType,
        key,
        works: results.map(work => ({ ...work, key: uuidv4() })),
      }
    })

    return Promise.all(requests)
  }

  public update(where: any, data?: any): Promise<any> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<any> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getList({ user: { email: '', password: '' } }, {})
    throw new Error('Method not implemented.')
  }
}

export const workTypesRepository = new WorkTypesRepository()
