






































































import {
  ref,
  watch,
  defineComponent,
  toRefs,
} from 'vue-demi'

import PreviewImage from '@/components/widgets/PreviewImage.vue'

interface Props extends Record<string, any> {
  readonly value: File[]
  readonly name: string
}

export default defineComponent<Props>({
  components: {
    PreviewImage,
  },

  props: {
    value: {
      type: Array,
      default: () => ([]),
      validator: (val: Props['value']) => val.every(v => v != null && typeof v === 'object'),
    },

    name: {
      type: String,
      default: 'file',
    },
  },

  setup(props, { emit }) {
    const { value: propValue } = toRefs(props)

    const isActive = ref<boolean>(false)

    const fileInputRef = ref<HTMLInputElement>()

    const fileList = ref<File[]>([]) // Store our uploaded files

    function onChange() {
      if (!fileInputRef.value || !fileInputRef.value.files) { return }
      fileList.value = [...fileInputRef.value.files]
      // @ts-ignore
      fileInputRef.value.value = null
    }

    function remove(i: number) {
      if (!fileInputRef.value || !fileInputRef.value.files) { return }
      fileList.value.splice(i, 1)
      fileInputRef.value.files[0]
    }

    function dragOver(event: WindowEventMap['dragover']) {
      event.preventDefault()
      isActive.value = true
    }

    function dragLeave() {
      isActive.value = false
    }

    function drop(event: WindowEventMap['drop']) {
      event.preventDefault()
      if (!fileInputRef.value || !event.dataTransfer) { return }
      isActive.value = false
      fileInputRef.value.files = event.dataTransfer?.files
      onChange()
    }

    watch(
      () => propValue.value,
      current => {
        if (!current.length && fileList.value.length) {
          fileList.value = []
        }
      },
    )

    watch(
      () => fileList.value,
      (current, prev) => {
        if (!current.length && !prev.length) { return }
        emit('input', current)
      },
    )

    return {
      isActive,
      fileInputRef,
      fileList,
      onChange,
      remove,
      dragOver,
      dragLeave,
      drop,
    }
  },
})

