



































import {
  ref,
  watch,
  toRefs,
  defineComponent,
} from 'vue-demi'

import { createPreviewUri } from '@/utils/files'

interface Props extends Record<string, any> {
  readonly file: File
}

export default defineComponent<Props>({
  props: {
    file: {
      type: File,
      required: true,
    },
  },

  setup(props) {
    const { file } = toRefs(props)

    const preview = ref<string>('')

    const previewName = ref<string>('')

    const image_prev = ref()

    watch(
      () => file.value,
      async () => {
        const { src } = await createPreviewUri(file.value)
        const mime = src.split(':')[1].split(';')[0]
        if (mime.split('/')[0] === 'image') {
          preview.value = src
        } else {
          const fileType = mime.split('/')[1]
          if (fileType === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            preview.value = require('../../assets/chat_file_types/xlsx.png')
          } else if (fileType === 'vnd.ms-excel') {
            preview.value = require('../../assets/chat_file_types/xls.png')
          } else if (fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
            preview.value = require('../../assets/chat_file_types/docx.png')
          } else if (fileType === 'msword') {
            preview.value = require('../../assets/chat_file_types/doc.png')
          } else if (fileType === 'vnd.oasis.opendocument.text') {
            preview.value = require('../../assets/chat_file_types/odt.png')
          } else {
            preview.value = require(`../../assets/chat_file_types/${mime.split('/')[1]}.png`)
          }
          image_prev.value.$el.classList.add('file_preview')
        }

        previewName.value = file.value.name
      },
      { immediate: true, deep: true },
    )

    return { preview, image_prev, previewName }
  },
})

