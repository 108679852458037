/* eslint-disable id-blacklist */
import { Maybe } from '@/types'

interface StorageEvent {
  key: string
  newValue: any
  oldValue: any
  url: string
  uri: string
}

export class StorageProvider {
  protected storage: Storage

  private listeners: Record<string, any>

  private listening: boolean

  private prevKey: string

  private prevValue: string

  public constructor(implementation: Storage) {
    this.storage = implementation
    this.listeners = {}
    this.listening = false
    this.prevKey = ''
    this.prevValue = ''
  }

  public set<T = any>(key: string, value: T) {
    this.storage.setItem(key, JSON.stringify(value))
  }

  public get<T = string>(key: string): Maybe<T> {
    const result = this.storage.getItem(key)
    if (!result || result === 'undefined') return null
    return JSON.parse(result) as T
  }

  public remove(key: string) {
    this.storage.removeItem(key)
  }

  public clear() {
    this.storage.clear()
  }

  public contains(key: string) {
    return !!this.storage.getItem(key)
  }

  public watch(key: string, fn: Function) {
    if (this.listeners[key]) {
      this.listeners[key].push(fn)
    } else {
      this.listeners[key] = [fn]
    }
    if (!this.listening) {
      this.listen()
    }
  }

  public unwatch(key: string, fn: Function) {
    const listener = this.listeners[key]
    if (listener.length > 1) {
      listener.splice(listener.indexOf(fn), 1)
    } else {
      this.listeners[key] = []
    }
  }

  private listen() {
    // @ts-ignore
    window.addEventListener('storage', this.change.bind(this))
  }

  private change(event: StorageEvent) {
    const all = this.listeners[event.key]
    if (all) { all.forEach((listener: Function) => this.fire(listener, event)) }
  }

  private fire(listener: Function, event: StorageEvent) {
    const {
      key,
      url, uri,
      oldValue,
      newValue,
    } = event
    const isPrevKey = this.prevKey === key
    const isPrevValue = this.prevValue === newValue

    if (isPrevKey && isPrevValue) return
    if (newValue !== oldValue) {
      listener(
        JSON.parse(newValue),
        JSON.parse(oldValue),
        url || uri,
      )
    }

    this.prevKey = key
    this.prevValue = newValue
  }
}

export const StorageService = new StorageProvider(localStorage)
export const LocalStorage = new StorageProvider(localStorage)
