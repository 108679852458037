import {
  CompaniesParams,
  Company,
  CompanyCreateInput,
  CompanyUpdateInput,
  CompanyWhereInput,
  ListWithMetaData,
  User,
  UserCreateInput,
} from '@/types/api'

import { logApiRequest } from '@/services'

import {
  BaseRestProvider,
  GET,
  POST,
  PUT,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  create: {
    path: '',
  },
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  update: {
    path: (id: string | number) => `/${id}`,
  },
  getList: {
    path: '',
  },
  getCompanyEmployersList: {
    path: (id: string | number) => `/${id}/users`,
  },
  createCompanyEmployer: {
    path: (id: string | number) => `/${id}/users`,
  },
}

// eslint-disable-next-line import/prefer-default-export
export class CompaniesProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/companies`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * create - get one user
   */
  public async create(payload: CompanyCreateInput, options?: RequestConfig): Promise<Company> {
    try {
      const { data } = await super[POST]<{ company: Company }>(routes.create.path, payload, options)
      logApiRequest('CompaniesProvider/create', payload, data)
      return data.company
    } catch (error: any) {
      console.error('CompaniesProvider/create', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }

  /**
   * getOne - get one user
   */
  public async getOne({ id }: CompanyWhereInput, options?: RequestConfig): Promise<Company> {
    try {
      const { data } = await super[GET]<{ company: Company }>(routes.getOne.path(id), options)
      logApiRequest('CompaniesProvider/getOne', { id }, data)
      return data.company
    } catch (error: any) {
      console.error('CompaniesProvider/getOne', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * getList - get user collection
   */
  public async getList(params: CompaniesParams, options?: RequestConfig) {
    const url = super.getUrlWithParams(routes.getList.path, params)

    try {
      const { data } = await super[GET]<ListWithMetaData<Company>>(url, options)
      logApiRequest('CompaniesProvider/getList', params, data)
      return data
    } catch (error: any) {
      console.error('CompaniesProvider/getList', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * update - update company
   */
  public async update({ id }: CompanyWhereInput, company: Partial<CompanyUpdateInput> | FormData, options?: RequestConfig) {
    const url = routes.update.path(id)

    const isFormData = company instanceof FormData
    const payload = isFormData ? company : { company }

    try {
      const { data } = await super[PUT]<{ company: Company }>(url, payload, options)
      logApiRequest('CompaniesProvider/update', { id }, data)
      return data.company
    } catch (error: any) {
      console.error('CompaniesProvider/update', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * getCompanyEmployersList - get company users collection
   */
  public async getCompanyEmployersList({ id }: CompanyWhereInput, options?: RequestConfig) {
    const url = routes.getCompanyEmployersList.path(id)

    try {
      const { data } = await super[GET]<ListWithMetaData<User>>(url, options)
      logApiRequest('CompaniesProvider/getCompanyEmployersList', { id }, data)
      return data
    } catch (error: any) {
      console.error('CompaniesProvider/getCompanyEmployersList', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * getCompanyEmployersList - get company users collection
   */
  public async createCompanyEmployer({ id }: CompanyWhereInput, payload: UserCreateInput, options?: RequestConfig) {
    const url = routes.createCompanyEmployer.path(id)

    try {
      const { data } = await super[POST]<{ user: User }>(url, payload, options)
      logApiRequest('CompaniesProvider/createCompanyEmployer', { id, payload }, data)
      return data
    } catch (error: any) {
      console.error('CompaniesProvider/createCompanyEmployer', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }
}

export const companiesProvider = new CompaniesProvider()
