import mitt, { Emitter, EventType, Handler } from 'mitt'

export class EventEmitter {
  private agent: Emitter

  public constructor() {
    this.agent = mitt()
  }

  public events() {
    return this.agent.all
  }

  public emit<T = any>(eventName: EventType, data?: T) {
    this.agent.emit(eventName, data)
  }

  public on<T = any>(eventName: string, fn: Handler<T>) {
    this.agent.on<T>(eventName, fn)
  }
}

// eslint-disable-next-line import/prefer-default-export
export const Events = new EventEmitter()
