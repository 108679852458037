



































































































import { inject, computed, defineComponent } from 'vue-demi'

import { RawLocation } from 'vue-router/types'

import { useStore } from '@/store'
import { ACTIONS, MODULES } from '@/store/types'

import { Routes, useRouter } from '@/router'

import AppHeaderNotifications from '@/components/layout/AppHeaderNotifications.vue'

import AppHeaderSearch from './AppHeaderSearch.vue'

interface MenuAction {
  key: string
  action?: Function
  location?: RawLocation
}

export default defineComponent({
  components: {
    AppHeaderSearch,
    AppHeaderNotifications,
  },

  setup() {
    const store = useStore()
    const router = useRouter()

    const isNotMobile = inject<boolean>('isNotMobile')
    const isNotTablet = inject<boolean>('isNotTablet')

    const profileRoute: RawLocation = {
      name: Routes.of.Me.OVERVIEW,
    }

    const profile = computed(() => store.state.Profile.user)

    function onMenuItemClick() {
      console.log('onMenuItemClick')
    }

    function doSignOut() {
      store.dispatch(`${MODULES.Auth}/${ACTIONS.Auth.processSignOut}`)
      router.replace({ name: Routes.of.Auth.LOGIN })
    }

    const menuActions: MenuAction[] = [
      {
        key: 'account',
        location: { name: Routes.of.Me.OVERVIEW },
      },
      {
        key: 'logout',
        action: doSignOut,
      },
    ]

    return {

      isNotMobile,
      isNotTablet,

      profile,
      profileRoute,

      doSignOut,
      onMenuItemClick,

      menuActions,
    }
  },
})

