var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"center"}},[(_vm.isNotTablet)?_c('AppHeaderNotifications'):_vm._e(),(_vm.profile && _vm.isNotTablet)?_c('v-btn',{staticClass:"mr-4",attrs:{"to":_vm.profileRoute,"color":"white","text":"","small":""}},[_vm._v(" "+_vm._s(_vm.profile.first_name || _vm.profile.last_name)+" ")]):_vm._e(),(_vm.profile)?_c('v-avatar',{attrs:{"color":"accent","rounded":"","size":"42"}},[_c('v-img',{attrs:{"src":_vm.profile.avatar}})],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","nudge-bottom":"54px","left":"","min-width":"190px","max-width":"290px","max-height":"540px","transition":"slide-y-transition","content-class":"profile-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","icon":"","small":"","disabled":!_vm.profile}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}])},[(_vm.profile)?_c('v-list',[_c('v-list-item',{staticClass:"px-4 user-select-none"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.profile.avatar || ("//cdn.vuetifyjs.com/images/lists/" + (_vm.profile.id) + ".jpg")}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.profile.first_name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.profile.actor_type)}})],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},_vm._l((_vm.menuActions),function(ref){
var key = ref.key;
var action = ref.action;
var location = ref.location;
return _c('v-list-item',{key:key,staticClass:"px-2 user-select-none",attrs:{"to":location},on:{"click":function($event){action ? action() : null}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"18"},domProps:{"textContent":_vm._s(("mdi-" + key))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t(("ui." + key)))}})],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }