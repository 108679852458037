import { RouteConfig } from 'vue-router/types'

import { MarketplaceNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const List = () => import(/* webpackChunkName: "services" */ '@/pages/Marketplace.vue')

const routes: RouteConfig[] = [
  {
    path: MarketplaceNames.path,
    component: Wrapper,

    children: [
      {
        path: '',
        name: MarketplaceNames.LIST,
        component: List,
        props: ({ query }) => query,
      },
    ],
  },
]

export default routes
