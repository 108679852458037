/* eslint-disable import/prefer-default-export */
export enum MODULES {
  Auth='Auth',
  Companies='Companies',
  Conversations='Conversations',
  Interface='Interface',
  Notifications='Notifications',
  Orders='Orders',
  Profile='Profile',
  Version='version',
  Workers='Workers',
  Reviews='Reviews',
  Marketplace='Marketplace'
}
