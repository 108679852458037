






































import { defineComponent, inject } from 'vue-demi'

export default defineComponent({
  setup() {
    return {
      isNotTablet: inject<boolean>('isNotTablet'),
    }
  },
})

