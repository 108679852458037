import { getCurrentInstance } from 'vue-demi'

import Vue from 'vue'
import Vuex, { Store } from 'vuex'

import { Environment } from '@/services'

import { RootState } from '@/types/store'

import modules from './modules'
import plugins from './plugins'

Vue.use(Vuex)

const store = new Store({
  modules,
  plugins,
  strict: Environment.isDevelopment,
})

// should be deprecate when use Vue 3
export function useStore<S = RootState>() {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new ReferenceError(
      'Vue instance not found.\n'
    + 'It looks like you trying to useStore() outside setup() function',
    )
  }
  return vm.proxy.$store as Store<S>
}

export function useStaticStore() {
  return store
}

export default store
