/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

import { Company, User } from '@/types/api'

import { ACTIONS, GETTERS, MUTATIONS } from '@/store/types'

import { companiesProvider } from '@/providers/companies'

import { usersRepository } from '@/repositories/users'

import { decodeJWT } from '@/utils/strings'

const module: Module<RootState['Profile'], RootState> = {
  namespaced: true,

  state: {
    user: null,
    company: null,
  },

  getters: {
    [GETTERS.Profile.companyHasWorks]: state => state.company && state.company.works.length,
    [GETTERS.Profile.companyHasOrder]: state => (orderId: number) => state.company && state.company.order_ids.includes(orderId),
    [GETTERS.Profile.companyIsCustomer]: state => state.company && state.company.kind === 'customer',
    [GETTERS.Profile.companyIsContractor]: state => state.company && state.company.kind === 'contractor',
    [GETTERS.Profile.getUserId]: state => state.user?.id,
  },

  actions: {
    async [ACTIONS.Profile.loadProfile]({ commit, rootState }) {
      if (!rootState.Auth || !rootState.Auth.token) { return {} }
      const { user_id } = decodeJWT<{ user_id: number; expires: number }>(rootState.Auth.token)
      const user = await usersRepository.find({ id: user_id })
      commit(MUTATIONS.PROFILE.SET_USER, user)
      return user
    },

    async [ACTIONS.Profile.loadMyCompany]({ commit, state }) {
      if (!state.user) { return }
      const result = await companiesProvider.getOne({ id: state.user.company_id })
      commit(MUTATIONS.PROFILE.SET_MY_COMPANY, result)
    },
  },

  mutations: {
    [MUTATIONS.PROFILE.SET_USER](state, user: User) {
      state.user = user
    },
    [MUTATIONS.PROFILE.SET_MY_COMPANY](state, company: Company) {
      state.company = company
    },
  },

  modules: {},
}

export default module
