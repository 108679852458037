/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'

import { Order, OrdersOrderByParams } from '@/types/api'
import { RootState } from '@/types/store'

import { ordersRepository } from '@/repositories/orders'

import { ACTIONS, MUTATIONS } from '../types'

const module: Module<RootState['Orders'], RootState> = {
  namespaced: true,

  state: {
    list: [],
    page: 1,
  },

  getters: {},

  actions: {
    async [ACTIONS.Orders.loadList]({ commit }, { orderBy, page, changeOrder, search }: { page: number; search: string; orderBy: OrdersOrderByParams; changeOrder: boolean }) {
      let result
      if (search) {
        result = await ordersRepository.findMany(page, search, {}, undefined, orderBy, {})
      } else {
        result = await ordersRepository.findMany(page, '', {}, undefined, orderBy, {})
      }

      if (search && page === 1) {
        commit(MUTATIONS.ORDERS.CLEAR_LIST)
      }
      if (search === '' && page === 1) {
        commit(MUTATIONS.ORDERS.SET_LIST, result)
      }
      if (!changeOrder || page !== 1) {
        result.forEach(elem => {
          commit(MUTATIONS.ORDERS.ADD_TO_LIST, elem)
        })
      } else {
        commit(MUTATIONS.ORDERS.SET_LIST, result)
      }
      return result
    },
  },

  mutations: {
    [MUTATIONS.ORDERS.ADD_TO_LIST](state, payload: Order) {
      const isAlreadyIn = state.list.findIndex(elem => elem.id === payload.id) !== -1
      if (!isAlreadyIn) {
        state.list.push(payload)
      }
    },

    [MUTATIONS.ORDERS.SET_LIST](state, payload: Order[]) {
      state.list = payload
    },

    [MUTATIONS.ORDERS.CLEAR_LIST](state) {
      state.list = []
    },
  },

  modules: {},
}

export default module
