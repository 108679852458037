






































































































































































// packages
import {
  ref,
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'
import { RawLocation } from 'vue-router/types'
import { useToast } from 'vue-toastification/composition'

import { createMachine } from '@xstate/fsm'

// Typings
import { SignUpPayload } from '@/types/api'
import { VForm } from '@/types/vuetify'

// Localization
import { useI18n } from '@/i18n'
// Router
import { Routes } from '@/router'

import { useMachine } from '@/utils/state-machine'

import { RegistrationProvider } from '@/providers'

// Utils
import {
  rules,
  validateForm,
  unValidateForm,
} from '@/utils/validators'

const registrationProvider = new RegistrationProvider()

interface Props extends Record<string, any> {
  readonly email: string
}

const machine = createMachine({
  id: 'sign-up-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent<Props>({
  components: {},

  props: {
    // from router
    email: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { email } = toRefs(props)

    const i18n = useI18n()
    const toast = useToast()

    const showPassword = ref(false)

    const formRef = ref<VForm>()

    const errors = ref({})

    const showConfirmationDialog = ref(false)

    const selectRoleDialog = ref(true)

    const state = reactive<SignUpPayload>({
      user: {
        full_name: '',
        email: process.env.NODE_ENV === 'development' ? 'superadmin@test' : '',
        password: '',
        password_confirmation: '',
      },
    })

    const { state: loadingState, send } = useMachine(machine)

    const loading = computed(() => loadingState.value.matches('active'))

    const signInRoute = computed<RawLocation>(() => ({ name: Routes.of.Auth.LOGIN }))

    function onEmailChange(value: string) {
      state.user.email = value
    }

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      const { email: authEmail, password } = state.user

      if (!authEmail.length || !password.length) { unValidateForm(formRef.value); return }

      try {
        send('TOGGLE')

        const result = await registrationProvider.signUp(state)

        if (!result.user.id) { throw new Error('no user id in auth response') }

        onSuccessSubmit()
      } catch (error) {
        errors.value = error
        toast.error(error.toString())
      } finally {
        send('TOGGLE')
      }
    }

    function onSuccessSubmit() {
      toast.success(i18n.t('ui.success.on_sign_up'))
      showConfirmationDialog.value = true

      state.user.email = ''
      state.user.password = ''
      state.user.password_confirmation = ''

      unValidateForm(formRef.value)
    }

    watch(
      () => email.value,
      onEmailChange,
    )

    return {
      state,
      formRef,
      errors,
      rules,
      onSubmit,
      showPassword,
      loading,
      signInRoute,

      selectRoleDialog,
      showConfirmationDialog,
    }
  },
})

