/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

import { marketplaceProvider } from '@/providers/marketplace'

import { ACTIONS, MUTATIONS } from '../types'

const module: Module<RootState['Marketplace'], RootState> = {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {},

  actions: {
    async [ACTIONS.Marketplace.fetchMarketplaceItems]({ commit }, { page, work_id, search }: { page: number; work_id: number | string; search: string}) {
      const marketplaceItems = await marketplaceProvider.getList({}, page, work_id, search)
      if (page === 1) {
        commit(MUTATIONS.MARKETPLACE.CLEAR_MARKETPLACE_LIST)
      }
      marketplaceItems.results.forEach(elem => {
        commit(MUTATIONS.MARKETPLACE.ADD_MARKETPLACE_ITEM, elem)
      })
    },
  },

  mutations: {
    [MUTATIONS.MARKETPLACE.ADD_MARKETPLACE_ITEM](state, item: any) {
      const isExist = state.list.findIndex((elem: any) => elem.work_title === item.work_title && elem.id === item.id) !== -1
      if (isExist) return

      state.list.push(item)
      console.log(isExist)
    },
    [MUTATIONS.MARKETPLACE.CLEAR_MARKETPLACE_LIST](state) {
      state.list = []
    },
  },

  modules: {},
}

export default module
