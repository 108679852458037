











import {
  reactive,
  onMounted,
  defineComponent,
} from 'vue-demi'

import { useRouter } from '@/router'

interface State {
  active: boolean
}

export default defineComponent({
  components: {},

  setup() {
    const router = useRouter()

    const state: State = reactive({
      active: false,
    })

    router.beforeEach((to, from, next) => {
      state.active = true
      next()
    })

    router.afterEach(() => {
      state.active = false
    })

    onMounted(() => {
      state.active = false
    })

    return state
  },
})

