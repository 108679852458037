import axios from 'axios'

import { Authorization, Events } from '@/services'
import { RequestConfig, httpHeaders, ErrorData } from '@/providers/base'

export function transformRequestConfig(config: RequestConfig) {
  /**
   * * @AlexQuidditch
   * добавление токена авторизации к заголовкам запроса
   */
  const { bearer } = Authorization.getTokens()
  if (bearer) {
    const headers = {
      ...config.headers,
      [httpHeaders.authorizationHeader]: `Bearer ${bearer}`,
    }
    config.headers = headers
  }
  return config
}

export async function transformResponseError({ config, response }: ErrorData<any>) {
  console.groupCollapsed('%c Interceptor: MARTA -> transformResponseError', 'color: white; background-color: #fc7554')
  console.log('data:', response?.data)
  console.log('status:', response?.status)

  if (response?.status === 401 && config.url !== '/login') {
    console.dir(config, { depth: 10 })
    /**
     * * @AlexQuidditch
     * бесшовное обновление токена
     */
    try {
      const { token } = await Authorization.processRefreshToken()
      config.headers[httpHeaders.authorizationHeader] = `Bearer ${token}`
      return axios(config)
    } catch (error) {
      Events.emit('unauthorized')
    }
  }

  if (response?.status === 503 && !response.data) {
    response.data = { errors: ['Service Temporarily Unavailable'] }
  }

  if (response?.status === 410) {
    // @ts-ignore
    this.$emit('unauthorized')
  }

  /**
     * * @AlexQuidditch
     * приходит `{status: 400, error: "Bad Request"}`
     * этот код переносит error в errors
     * что согласует формат сообщений об ошибках с кодом в методах классов провайдера
     */
  if (response?.data.error && !response.data.errors) {
    // fallback на случай рецидивов бекенда
    response.data.errors = response.data.error
    delete response.data.error
  }

  console.groupEnd()
  throw response
}
