



























































































































































































































































import {
  computed,
  defineComponent,
  inject,
} from 'vue-demi'
import { RawLocation } from 'vue-router'

import { Routes } from '@/router'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const store = useStore()

    const isNotTablet = inject<boolean>('isNotTablet')

    const profile = computed(() => store.state.Profile.user)
    const notifications = computed<string[]>(() => store.state.Notifications.list)

    const ordersLocation = computed<RawLocation>(() => ({ name: Routes.of.Orders.LIST }))
    const marketplaceLocation = computed<RawLocation>(() => ({ name: Routes.of.Marketplace.LIST }))
    const messageListLocation = computed<RawLocation>(() => ({ name: Routes.of.Dialogs.LIST }))
    const serviceListLocation = computed<RawLocation>(() => ({ name: Routes.of.Services.LIST }))

    function getTextForNotificationsCount() {
      const stringNum = `${notifications.value.length}`
      const lastNum = stringNum[stringNum.length - 1]
      if (notifications.value.length === 0) {
        return 'У вас нет непрочитанных уведомлений'
      }
      if (lastNum === '1') {
        return `У Вас ${notifications.value.length} непрочитанное уведомление`
      }
      if (lastNum === '2' || lastNum === '3' || lastNum === '4') {
        return `У Вас ${notifications.value.length} непрочитанных уведомления`
      }
      return `У Вас ${notifications.value.length} непрочитанных уведомлений`
    }

    return {
      profile,
      isNotTablet,
      ordersLocation,
      marketplaceLocation,
      serviceListLocation,
      messageListLocation,
      notifications,
      getTextForNotificationsCount,
    }
  },
})

