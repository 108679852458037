/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */
import { Module } from 'vuex/types'

import { AuthResult } from '@/types/api'
import { RootState, Payload } from '@/types/store'
import { ACTIONS, GETTERS, MUTATIONS } from '@/store/types'

import { Authorization } from '@/services'

const module: Module<RootState['Auth'], RootState> = {
  namespaced: true,

  state: {
    id: null,
    token: null,
    refresh_token: null,
  },

  getters: {
    // getErrorsByKey: state => (key: string) => getFlatErrors(state.errors[key]),
    [GETTERS.Auth.isLoggedIn]: state => !!state.token,
  },

  actions: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [ACTIONS.Auth.setAuthState]({ commit }, { type, ...payload }: Payload<AuthResult>) {
      commit<Payload<AuthResult>>({
        type: MUTATIONS.AUTH.SET_AUTH_DATA,
        ...payload,
      })
    },

    [ACTIONS.Auth.processSignOut]({ commit }) {
      commit(MUTATIONS.AUTH.SIGN_OUT)
      Authorization.processSignOut()
    },
  },

  mutations: {
    [MUTATIONS.AUTH.SET_AUTH_DATA](state, { id, token }: Payload<AuthResult>) {
      if (!token) { return }
      state.id = id
      state.token = token

      state.refresh_token = localStorage.getItem('refreshToken')?.replace(/"/g, '')
    },

    [MUTATIONS.AUTH.SIGN_OUT](state) {
      state.id = null
      state.token = null
      state.refresh_token = null
    },
  },

  modules: {},
}

export default module
