/* eslint-disable import/prefer-default-export */
import { createNameSpace } from '@/utils/helpers'
import { DEFAULT_LIST_NAME, DEFAULT_OVERVIEW_NAME, DEFAULT_CREATE_NAME } from './constants'

export abstract class WorkersNames {
  public static get path() {
    return '/workers'
  }

  private static get nameSpace() {
    return createNameSpace('Workers')
  }

  public static get LIST() {
    return this.nameSpace.with(DEFAULT_LIST_NAME)
  }

  public static get OVERVIEW() {
    return this.nameSpace.with(DEFAULT_OVERVIEW_NAME)
  }

  public static get CREATE() {
    return this.nameSpace.with(DEFAULT_CREATE_NAME)
  }

  // eslint-disable-next-line class-methods-use-this
  public static toJSON(): Record<string, any> {
    return {
      namespace: WorkersNames.nameSpace,
      list: WorkersNames.LIST,
      overview: WorkersNames.OVERVIEW,
      create: WorkersNames.CREATE,
    }
  }
}
