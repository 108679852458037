






























































































import {
  ref,
  computed,
  defineComponent,
} from 'vue-demi'

import { Routes } from '@/router'

import { useStore } from '@/store'
import { MODULES, MUTATIONS } from '@/store/types'

import { VListItem } from '@/types/vuetify'
import { Payload, SetDrawerPayload } from '@/types/store'

import CreateOrderWidget from '@/components/widgets/CreateOrderWidget.vue'

export default defineComponent({
  components: {
    CreateOrderWidget,
  },

  setup() {
    const store = useStore()

    const modal = ref(false)

    const companyKind = computed(() => store.state.Profile.company?.kind)

    const drawer = computed<boolean>({
      get: () => store.state.Interface.drawers.menu,
      set: v => store.commit<Payload & SetDrawerPayload>({
        type: `${MODULES.Interface}/${MUTATIONS.INTERFACE.SET_DRAWER}`,
        key: 'menu',
        value: v,
      }),
    })

    const list: VListItem[] = [
      {
        id: 0,
        icon: 'mdi-buffer',
        title: 'Все заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 1,
        icon: 'mdi-chart-bar',
        title: 'Активные заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 2,
        icon: 'mdi-check-circle-outline',
        title: 'Выполненные заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 3,
        icon: 'mdi-file-document-outline',
        title: 'Торги',
        route: { name: Routes.of.Orders.LIST },
      },
    ]

    const links: VListItem[] = [
      {
        id: 0,
        icon: 'mdi-check-circle-outline',
        title: 'ui.marketplace',
        route: { name: Routes.of.Marketplace.LIST },
      },
      {
        id: 1,
        icon: 'mdi-check-circle-outline',
        title: 'ui.my_orders',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 2,
        icon: 'mdi-account-outline',
        title: 'ui.my_company',
        route: { name: Routes.of.MyCompany.OVERVIEW },
      },
      {
        id: 3,
        icon: 'mdi-heart-outline',
        title: 'ui.services',
        route: { name: Routes.of.Services.LIST },
      },
      {
        id: 4,
        icon: 'mdi-email-outline',
        title: 'ui.dialogs',
        route: { name: Routes.of.Dialogs.LIST },
      },
    ]

    return {
      list,
      links,
      modal,
      drawer,
      companyKind,
    }
  },
})

