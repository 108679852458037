































































































































// packages
import {
  ref,
  watch,
  toRefs,
  reactive,
  computed,
  defineComponent,
} from 'vue-demi'
import { RawLocation } from 'vue-router/types'
import { useToast } from 'vue-toastification/composition'

import { createMachine } from '@xstate/fsm'

// Typings
import {
  AuthResult,
  AuthPayload,
} from '@/types/api'
import { Payload } from '@/types/store'

// Localization
import { useI18n } from '@/i18n'
// Router
import { useRouter, Routes } from '@/router'

// Store
import { useStore } from '@/store'
import { MODULES, ACTIONS } from '@/store/types'
import { processStoreActions } from '@/store/utils'

import { useMachine } from '@/utils/state-machine'

import { Authorization } from '@/services/authorization'

// Utils
import {
  rules,
  validateForm,
  unValidateForm,
} from '@/utils/validators'
import { VForm } from '@/types/vuetify'

function normalizeUri(uri: string): string {
  const result = decodeURIComponent(uri)
  if (result.includes('%2F')) {
    return normalizeUri(result)
  }
  return result
}

const machine = createMachine({
  id: 'sign-in-machine',
  initial: 'inactive',
  states: {
    inactive: {
      on: { TOGGLE: 'active' },
    },
    active: {
      on: { TOGGLE: 'inactive' },
    },
  },
})

export default defineComponent({
  props: {
    // from router
    confirm: {
      type: String,
      default: '',
    },

    redirect: {
      type: String,
      default: '',
    },

    // from parent
    inApp: {
      type: Boolean,
      default: false,
    },

    email: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const i18n = useI18n()
    const toast = useToast()
    const store = useStore()
    const router = useRouter()

    const {
      confirm, email,
      redirect, inApp,
    } = toRefs(props)

    const { state: loadingState, send } = useMachine(machine)

    const state = reactive<AuthPayload>({
      user: {
        email: process.env.NODE_ENV === 'development' ? 'superadmin@test' : '',
        password: '',
      },
    })

    const showPassword = ref(false)

    const formRef = ref<VForm>()

    const loading = computed(() => loadingState.value.matches('active'))

    const sberLoading = ref(false)

    const signUpRoute = computed<RawLocation>(() => ({ name: Routes.of.Auth.SIGN_UP }))

    if (confirm.value.length) {
      state.user.email = decodeURI(confirm.value)
    }

    if (email.value.length) {
      state.user.email = decodeURI(email.value)
    }

    const errors = ref({})

    function onEmailChange(value: string) {
      state.user.email = value
    }

    async function onSubmit() {
      const isValid = validateForm(formRef.value)
      if (!isValid) { return }

      const { email: authEmail, password } = state.user

      if (!authEmail.length || !password.length) {
        unValidateForm(formRef.value)
        return
      }

      try {
        send('TOGGLE')

        const result = await Authorization.processSignIn(state)

        onSuccessSubmit(result)
      } catch (error) {
        errors.value = error
        toast.error(i18n.t(error.toString()))
      } finally {
        send('TOGGLE')
      }
    }

    async function onSuccessSubmit(authResult: AuthResult) {
      toast.success(i18n.t('ui.success.on_sign_in'))

      if (authResult.id == null) { throw new Error('no user id in auth response') }

      await store.dispatch<Payload<AuthResult>>({
        type: `${MODULES.Auth}/${ACTIONS.Auth.setAuthState}`,
        ...authResult,
      })

      store.dispatch({
        type: `${MODULES.Profile}/${ACTIONS.Profile.loadProfile}`,
      }).then(() => processStoreActions([
        `${MODULES.Profile}/${ACTIONS.Profile.loadMyCompany}`,
        `${MODULES.Conversations}/${ACTIONS.Conversations.createSubscription}`,
        `${MODULES.Notifications}/${ACTIONS.Notifications.createSubscription}`,
        `${MODULES.Conversations}/${ACTIONS.Conversations.fetchConversationsList}`,
      ]))

      if (confirm.value.length > 2) {
        router.replace({ name: Routes.of.Services.LIST })
        return
      }

      const normalizedRedirectUrl = normalizeUri(redirect.value)

      if (redirect.value && normalizedRedirectUrl.length > 1) {
        router.replace(normalizedRedirectUrl)
        return
      }

      if (inApp.value) {
        emit('sign-in')
        return
      }

      router.replace({ name: Routes.of.Services.LIST })
    }

    async function signInWithSberBusinessId() {
      sberLoading.value = true
      const { location } = await Authorization.signInWithSberBusinessId()
      if (!location) {
        toast.error(i18n.t('ui.errors.on_sign_in'))
        return
      }
      window.location.href = location
    }

    watch(
      () => email.value,
      onEmailChange,
    )

    return {
      state,
      send,
      rules,
      formRef,
      errors,
      loading,
      onSubmit,
      signUpRoute,
      sberLoading,
      showPassword,
      signInWithSberBusinessId,
    }
  },
})

