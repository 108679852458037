import { RouteConfig } from 'vue-router/types'

import { AuthNames } from '@/router/names'

import { Authorization } from '@/services'

import Wrapper from '@/components/auth/wrapper.vue'

// import SignUp from '@/components/auth/SignUpForm.vue';
import SignInForm from '@/components/auth/SignInForm.vue'
import SignUpStepper from '@/components/auth/SignUpStepper.vue'
import SbbolCallbackPage from '@/components/auth/SbbolCallbackPage.vue'
// import PasswordNew from '@/components/views/auth/PasswordNew.vue';
// import SignOut from '@/components/auth/SignOut.vue';

// import Auth from '@/components/views/auth/index.vue';

const routes: RouteConfig[] = [
  {
    path: '/auth',
    component: Wrapper,
    beforeEnter(to, from, next) {
      if (to.name !== 'Auth/signOut' && Authorization.isLoggedIn()) {
        return next({ name: 'Main' })
      }
      return next()
    },

    children: [
      {
        path: '',
        name: AuthNames.BASE,
        // component: Auth,
        meta: { allowAnonymous: true },
        redirect: { name: AuthNames.LOGIN },
      },

      {
        path: 'login',
        alias: '',
        name: AuthNames.LOGIN,
        component: SignInForm,
        meta: { allowAnonymous: true },
        props: ({ query }) => ({ ...query }),
      },

      {
        path: 'sign-up',
        alias: '',
        name: AuthNames.SIGN_UP,
        component: SignUpStepper,
        meta: { allowAnonymous: true },
        props: ({ query }) => ({
          token: query.t, // токен для подтверждения почты из письма
          userId: query.u, // ID пользователя для этапа выбора роли
          role: query.r, // выбранная пользователем роль
        }),
      },

      {
        path: 'callback/success',
        component: SbbolCallbackPage,
        meta: { allowAnonymous: true },
        props: ({ query }) => ({
          code: query.code,
          nonce: query.nonce,
          state: query.state,
        }),
      },

      // {
      //   path: 'sign-up',
      //   name: AuthNames.SIGN_UP,
      //   component: SignUp,
      //   meta: { allowAnonymous: true },
      //   props: ({ query }) => ({
      //     invitation: query.i,
      //   }),
      // },

      // {
      //   path: 'password/new',
      //   alias: 'password',
      //   name: AuthNames.with('passwordNew'),
      //   component: PasswordNew,
      //   meta: { allowAnonymous: true },
      //   props: ({ query }) => ({ ...query }),
      // },

      // {
      //   path: 'sign-out',
      //   name: AuthNames.SIGN_OUT,
      //   component: SignOut,
      // },
    ],
  },
]

export default routes
