import { logApiRequest } from '@/services'
import {
  ConfirmEmailPayload,
  ConfirmEmailResult,
  SignUpPayload,
  SignUpResult,
} from '@/types/api'

import { BaseRestProvider, POST } from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  signUp: {
    method: POST,
    path: '/',
  },
  confirmEmail: {
    method: POST,
    path: '/confirm_email',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class RegistrationProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/registration`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * signIn
   */
  public async signUp(payload: SignUpPayload): Promise<SignUpResult> {
    try {
      const { data } = await super[POST]<SignUpResult>(routes.signUp.path, payload)
      logApiRequest('RegistrationProvider/signUp', payload, data)
      return data
    } catch (error) {
      console.error('RegistrationProvider/signUp', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }

  /**
   * signIn
   */
  public async confirmEmail(payload: ConfirmEmailPayload): Promise<ConfirmEmailResult> {
    try {
      const { data } = await super[POST]<ConfirmEmailResult>(routes.confirmEmail.path, payload)
      logApiRequest('RegistrationProvider/confirmEmail', payload, data)
      return data
    } catch (error) {
      console.error('RegistrationProvider/confirmEmail', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }
}
