import { RouteConfig } from 'vue-router/types'

import Wrapper from '@/components/wrapper.vue'
import Main from '@/pages/Main.vue'

import auth from './auth'
import companies from './companies'
import dialogs from './dialogs'
import me from './me'
import orders from './orders'
import services from './services'
import workers from './workers'
import marketplace from './marketplace'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Wrapper,
    redirect: () => ({ name: 'Main' }),
    children: [
      {
        path: 'welcome',
        name: 'Main',
        component: Main,
      },
    ],
  },

  ...auth,
  ...companies,
  ...dialogs,
  ...me,
  ...orders,
  ...services,
  ...workers,
  ...marketplace,
]

export default routes
