/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'
import { usersRepository } from '@/repositories'

const module: Module<RootState['Users'], RootState> = {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    async loadList() {
      const result = await usersRepository.findMany({}, {}, undefined, {})
      console.log({ result })
      return result
    },
  },

  mutations: {},

  modules: {},
}

export default module
