import {
  ListWithMetaData,
  WorkType,
  WorkTypesParams,
  WorkTypesWhereInput,
} from '@/types/api'

import { logApiRequest } from '@/services'

import {
  GET,
  BaseRestProvider,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  getList: {
    path: '',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class WorkTypesProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/work_types/collection`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * getOne - get one user
   */
  public async getOne({ id }: WorkTypesWhereInput, options?: RequestConfig): Promise<WorkType> {
    try {
      const { data } = await super[GET]<{ workType: WorkType }>(routes.getOne.path(id), options)
      logApiRequest('WorkTypesProvider/getOne', { id }, data)
      return data.workType
    } catch (error: any) {
      console.error('WorkTypesProvider/getOne', { config: error.config, data: error.data })
      throw error.response
    }
  }

  /**
   * getList - get user collection
   */
  public async getList(params: WorkTypesParams, options?: RequestConfig): Promise<ListWithMetaData<WorkType>> {
    const url = super.getUrlWithParams(routes.getList.path, params)

    try {
      const { data } = await super[GET]<ListWithMetaData<WorkType>>(url, options)
      logApiRequest('WorkTypesProvider/getList', params, data)
      return data
    } catch (error: any) {
      console.error('WorkTypesProvider/getList', { config: error.config, data: error.data })
      throw error.response
    }
  }
}
