/* eslint-disable import/prefer-default-export */
import { createNameSpace } from '@/utils/helpers'
import { DEFAULT_OVERVIEW_NAME } from './constants'

export abstract class MyCompanyNames {
  public static get path() {
    return '/my-company'
  }

  private static get nameSpace() {
    return createNameSpace('MyCompany')
  }

  public static get OVERVIEW() {
    return this.nameSpace.with(DEFAULT_OVERVIEW_NAME)
  }

  public static get REQUISITES() {
    return this.nameSpace.with('requisites')
  }

  public static get WORKERS() {
    return this.nameSpace.with('workers')
  }

  public static get DOCUMENTS() {
    return this.nameSpace.with('documents')
  }

  public static get REVIEWS() {
    return this.nameSpace.with('reviews')
  }

  // eslint-disable-next-line class-methods-use-this
  public static toJSON(): Record<string, any> {
    return {
      namespace: MyCompanyNames.nameSpace,
      overview: MyCompanyNames.OVERVIEW,
      requisites: MyCompanyNames.REQUISITES,
      works: MyCompanyNames.WORKERS,
      documents: MyCompanyNames.DOCUMENTS,
      reviews: MyCompanyNames.REVIEWS,
    }
  }
}
