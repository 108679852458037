import {
  sub,
  add,
  parse,
  format,
  parseISO,
  subYears,
  formatRelative,
  eachDayOfInterval,
  eachHourOfInterval,
  formatDistance,
  formatDistanceToNowStrict,
  isWithinInterval,
} from 'date-fns/esm'
import { Duration } from 'date-fns'

import ru from 'date-fns/locale/ru'

const MIN_AVAILABLE_AGE = 14
const MAX_AVAILABLE_AGE = 100

export const YEARS_FROM_MIN_AVAILABLE_AGE = getSubYearDateWithMask(MAX_AVAILABLE_AGE)
export const YEARS_FROM_MAX_AVAILABLE_AGE = getSubYearDateWithMask(MIN_AVAILABLE_AGE)

export function parseWithMask(dateString: string, mask: string) {
  return parse(dateString, mask, new Date())
}

export function addValueToDateByDuration(date: string, duration: Duration) {
  return add(parseISO(date), duration).toISOString()
}

export function subValueFromDateByDuration(date: string, duration: Duration) {
  return sub(parseISO(date), duration).toISOString()
}

export function getDaysInRange(start: string, end: string) {
  return eachDayOfInterval({
    start: parseISO(start),
    end: parseISO(end),
  }).map(v => formatDateWithMask(v.toISOString()))
}

export function formatDateWithMask(date: string, mask = 'yyyy-MM-dd') {
  return format(
    parseISO(date ?? new Date().toISOString()),
    mask,
    { locale: ru },
  )
}

export function getSubYearDateWithMask(years: number, mask = 'yyyy-MM-dd') {
  return format(
    subYears(new Date(), years),
    mask,
    { locale: ru },
  )
}

export function formatRelativeDate(date: string) {
  return formatRelative(
    parseISO(date),
    new Date(),
    { locale: ru },
  )
}

export function formatDistanceFromDateToNow(
  date: string,
) {
  if (!date) { return 'NaN' }

  return formatDistance(
    parseISO(date),
    new Date(),
    {
      includeSeconds: true,
      addSuffix: true,
      locale: ru,
    },
  )
}

export function formatDistanceFromDateToNowStrict(
  date: string,
  unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' = 'year',
  roundingMethod: 'floor' | 'ceil' | 'round' = 'floor',
) {
  return formatDistanceToNowStrict(
    parseISO(date),
    {
      unit,
      roundingMethod,
      locale: ru,
    },
  )
}

export function getHoursOfInterval(start: string, end: string) {
  return eachHourOfInterval({
    start: parseISO(start),
    end: parseISO(end),
  })
}

export function sortDateListAsc(prev: Date, next: Date) {
  if (prev > next) return 1
  if (prev < next) return -1
  return 0
}

export function getLocaleString(date: string) {
  return new Date(date).toLocaleString()
}

export function getLocaleDateString(date: string) {
  return new Date(date).toLocaleDateString(
    window.navigator.language,
    {
      year: '2-digit',
      day: '2-digit',
      month: '2-digit',
    },
  )
}

export function getBirthdayString(date: string) {
  return new Date(date.substring(0, 10)).toLocaleDateString()
}

export function isGreaterThanNow(date: string) {
  return new Date(date) > new Date()
}

export function isNowWithinInterval(_start: Date | number, _end: Date | number) {
  const now = new Date()
  const start = new Date(_start)
  const end = new Date(_end)
  return isWithinInterval(now, { start, end })
}
