/* eslint-disable import/prefer-default-export */
import {
  Order,
  OrderCreateInput,
  OrdersOrderByParams,
} from '@/types/api'

import { OrdersProvider } from '@/providers/orders'

import { DefaultCrudRepository, EntityWhereManyInputOptions } from './base'

export class OrdersRepository implements DefaultCrudRepository<
Order,
any,
any,
any,
any,
any,
any
> {
  private provider: OrdersProvider;

  public constructor() {
    this.provider = new OrdersProvider()
  }

  public create(data: OrderCreateInput): Promise<Order> {
    return this.provider.create(data)
  }

  public async createMany(data: OrderCreateInput[]): Promise<Order[]> {
    const requests = data.map(order => this.create(order))

    const response = await Promise.allSettled(requests)

    return response.reduce<Order[]>((accumulator, request) => {
      if (request.status === 'fulfilled') { accumulator.push(request.value) }
      return accumulator
    }, [])
  }

  public save(data: any): Promise<Order> {
    console.log({ data })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  public find(where: { id: number | string }) {
    return this.provider.getOne({ id: where.id })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async findMany(page: number, search: string, where: any, work_id?: number | string, orderBy?: OrdersOrderByParams, options?: EntityWhereManyInputOptions) {
    const { results } = await this.provider.getList({ ...where }, { ...orderBy }, page, search, {})
    return results
  }

  public update(where: any, data?: any): Promise<Order> {
    const resp = this.provider.update({ id: where.id }, data.order)
    return resp
  }

  public updateMany(where: any, data?: any): Promise<{ count: number }> {
    console.log({ data })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  public delete(where: any): Promise<Order> {
    console.log({ where })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  public deleteMany(where: any): Promise<{ count: number }> {
    console.log({ where })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  public count(where: any, orderBy?: any, options?: EntityWhereManyInputOptions): Promise<number> {
    console.log({ where, orderBy, options })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }

  public isExist(where: any): Promise<boolean> {
    console.log({ where })
    this.provider.getOne({ id: 0 }, {})
    throw new Error('Method not implemented.')
  }
}

export const ordersRepository = new OrdersRepository()
