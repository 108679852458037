























import { defineComponent } from 'vue-demi'

import SignUpForm from './SignUpForm.vue'
import SelectRoleForm from './SelectRoleForm.vue'
import EmailConfirmation from './EmailConfirmation.vue'

import ContinueAsCompany from './ContinueAsCompany.vue'

export default defineComponent({
  components: {
    SignUpForm,
    SelectRoleForm,
    EmailConfirmation,

    ContinueAsCompany,
  },

  props: {
    // from router
    email: {
      type: String,
      default: '',
    },

    token: {
      type: String,
    },

    userId: {
      type: String,
    },

    role: {
      type: String,
    },
  },
})

