import { RouteConfig } from 'vue-router/types'

import { WorkersNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const Worker = () => import(/* webpackChunkName: "workers" */ '@/pages/Worker.vue')
const List = () => import(/* webpackChunkName: "workers" */ '@/pages/WorkerList.vue')

const routes: RouteConfig[] = [
  {
    path: WorkersNames.path,
    component: Wrapper,

    children: [
      {
        path: '',
        name: WorkersNames.LIST,
        component: List,
        props: ({ query }) => query,
      },

      {
        path: '',
        name: WorkersNames.CREATE,
        redirect: () => ({
          name: WorkersNames.LIST,
          query: { 'add-dialog': 'true' },
        }),
      },

      {
        path: ':id/:section?',
        name: WorkersNames.OVERVIEW,
        component: Worker,
        props: ({ query, params }) => ({ ...query, ...params }),
      },
    ],
  },
]

export default routes
