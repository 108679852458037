import { ListWithMetaData, User, UserParams } from '@/types/api'

import { logApiRequest } from '@/services'

import {
  GET,
  PUT,
  BaseRestProvider,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {
  getOne: {
    path: (id: string | number) => `/${id}`,
  },
  update: {
    path: (id: string | number) => `/${id}`,
  },
  getList: {
    path: '',
  },
}

// eslint-disable-next-line import/prefer-default-export
export class UsersProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/users`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  /**
   * getOne - get one user
   */
  public async getOne({ id }: UserParams, options?: RequestConfig): Promise<User> {
    try {
      const { data } = await super[GET]<{ user: User }>(routes.getOne.path(id), options)
      logApiRequest('UsersProvider/getOne', { id }, data)
      return data.user
    } catch (error: any) {
      console.error('UsersProvider/getOne', { config: error.config, data: error.data })
      throw error
    }
  }

  /**
   * update - get one user
   */
  public async update({ id }: UserParams, payload: FormData, options?: RequestConfig): Promise<User> {
    try {
      const { data } = await super[PUT]<{ user: User }>(routes.update.path(id), payload, options)
      logApiRequest('UsersProvider/update', { id }, data)
      return data.user
    } catch (error: any) {
      console.error('UsersProvider/update', { config: error.config, data: error.data })
      throw error.data.errors
    }
  }

  /**
   * getList - get user collection
   */
  public async getList(params: any, options?: RequestConfig): Promise<ListWithMetaData<User>> {
    const url = super.getUrlWithParams(routes.getList.path, params)

    try {
      const { data } = await super[GET]<ListWithMetaData<User>>(url, options)
      logApiRequest('UsersProvider/getList', params, data)
      return data
    } catch (error: any) {
      console.error('UsersProvider/getList', { config: error.config, data: error.data })
      throw error
    }
  }
}

export const usersProvider = new UsersProvider()
