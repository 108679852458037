

















































































import { ref, defineComponent, computed } from 'vue-demi'
import { RawLocation } from 'vue-router/types'

import { Routes } from '@/router'
import { useStore } from '@/store'

import CreateOrderWidget from '@/components/widgets/CreateOrderWidget.vue'

interface ListItem {
  id: number
  title: string
  icon: string
  route: RawLocation
}

export default defineComponent({
  components: {
    CreateOrderWidget,
  },

  setup() {
    const store = useStore()

    const modal = ref(false)

    const companyKind = computed(() => store.state.Profile.company?.kind)

    const list: ListItem[] = [
      {
        id: 0,
        icon: 'mdi-plus',
        title: 'Все заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 1,
        icon: 'mdi-plus',
        title: 'Активные заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 2,
        icon: 'mdi-plus',
        title: 'Выполненные заявки',
        route: { name: Routes.of.Orders.LIST },
      },
      {
        id: 3,
        icon: 'mdi-plus',
        title: 'Торги',
        route: { name: Routes.of.Orders.LIST },
      },
    ]

    return {
      companyKind,
      modal,
      list,
    }
  },
})

