import { RouteConfig } from 'vue-router/types'

import { CompaniesNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const Order = () => import(/* webpackChunkName: "company" */ '@/pages/Company.vue')

const routes: RouteConfig[] = [
  {
    path: CompaniesNames.path,
    component: Wrapper,

    children: [
      {
        path: ':id/:section?',
        name: CompaniesNames.OVERVIEW,
        component: Order,
        props: ({ params }) => ({ ...params }),
      },
    ],
  },
]

export default routes
