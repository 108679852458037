import { useStaticI18n } from '@/i18n'
import { VForm } from '@/types/vuetify'

export function required(value: string) {
  return !!value || useStaticI18n().t('required_field')
}

export function shouldBeSize(value: File) {
  return !value || value.size < 7000000 || 'Avatar size should be less than 7 MB!'
}

export function isEqual<T = string>(value: T, compare: T) {
  return value === compare
}

export function validateForm(ref?: VForm): boolean {
  if (!ref) { throw new ReferenceError('form ref is undefined') }
  return ref.validate()
}

export function unValidateForm(ref?: VForm) {
  if (!ref) { throw new ReferenceError('form ref is undefined') }
  return ref.resetValidation()
}

export const rules = {
  required,
  isEqual,
  shouldBeSize,
}
