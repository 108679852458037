import { logApiRequest } from '@/services'
import {
  CreateReviewParams, ListWithMetaData, Review,
} from '@/types/api'

import {
  BaseRestProvider,
  GET,
  POST,
  RequestConfig,
} from './base'

import {
  transformRequestConfig,
  transformResponseError,
} from './interceptors/marta'

const routes = {

  create: {
    path: '/reviews',
  },
  getList: {
    path: (id: string) => `/companies/${id}/reviews`,
  },
}

// eslint-disable-next-line import/prefer-default-export
export class ReviewsProvider extends BaseRestProvider {
  public constructor() {
    super(
      `${process.env.VUE_APP_API_ENDPOINT}/`,
      transformRequestConfig,
      undefined,
      undefined,
      transformResponseError,
    )
  }

  // eslint-disable-next-line
  public async create(review: CreateReviewParams, options?: RequestConfig): Promise<Review> {
    try {
      const { data } = await super[POST](routes.create.path, review)
      logApiRequest('ReviewsProvider/create')
      // @ts-ignore
      return data
    } catch (error: any) {
      if (process.env.NODE_ENV !== 'production') { console.error('ReviewsProvider/create', { config: error.config, data: error.data }) }
      throw error.data.errors
    }
  }

  /**
   * getList - get reviews collection
   */
  public async getList(id: string, params?: CreateReviewParams, options?: RequestConfig) {
    const url = super.getUrlWithParams(routes.getList.path(id), params)

    try {
      const { data } = await super[GET]<ListWithMetaData<Review>>(url, options)
      logApiRequest('ReviewsProvider/getList', params, data)
      return data
    } catch (error: any) {
      console.error('ReviewsProvider/getList', { config: error.config, data: error.data })
      throw error.response
    }
  }
}

export const reviewsProvider = new ReviewsProvider()
