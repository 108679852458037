/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable object-curly-newline */

import { Module } from 'vuex/types'
import { RootState } from '@/types/store'

// import { Authorization } from '@/services'
import { ReviewsProvider } from '@/providers/reviews'
// import { ReviewsRepository } from '@/repositories/reviews'

import { ACTIONS, GETTERS, MUTATIONS } from '../types'

const reviewsProvider = new ReviewsProvider()
// const reviewsRepository = new ReviewsRepository()

const module: Module<RootState['Reviews'], RootState> = {
  namespaced: true,

  state: {
    reviews: [],
  },

  getters: {
    [GETTERS.Reviews.getReviewsList]: state => (id: string) => {
      const index = state.reviews?.findIndex(((company): any => company.id === id))
      return state.reviews[index]?.reviews || []
    },
    [GETTERS.Reviews.getReviewsListLength]: state => (id: string) => {
      const index = state.reviews?.findIndex(((company): any => company.id === id))
      return state.reviews[index]?.reviews?.results.length || 0
    },
  },

  actions: {
    async [ACTIONS.Reviews.fetchReviewsList]({ commit, state }, { id, params }: any) {
      const reviews = await reviewsProvider.getList(id, params)
      commit(MUTATIONS.REVIEWS.SET_LIST, { reviews, id })
      return state.reviews || []
    },
  },

  mutations: {
    [MUTATIONS.REVIEWS.SET_LIST](state, { reviews, id }: any) {
      const index = state.reviews.findIndex(company => company.id === id)
      if (index !== -1) {
        state.reviews[index].reviews = reviews
      } else {
        state.reviews.push({ id, reviews })
      }
    },
    [MUTATIONS.REVIEWS.ADD_TO_LIST](state, { review, id }: any) {
      const index = state.reviews.findIndex(company => company.id === id)
      if (index !== -1) {
        state.reviews[index].reviews.push(review)
      } else {
        state.reviews.push({ id, reviews: [review] })
      }
    },
  },

  modules: {},
}

export default module
