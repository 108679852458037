import {
  getCurrentInstance,
} from 'vue-demi'

import Vue from 'vue'
import Router, { Route } from 'vue-router'

import { Environment } from '@/services'

import routes from './routes'

import names from './names'

import { parseQuery, stringifyQuery } from './query'
import { checkAuth, checkDuplicate } from './hooks'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: Environment.baseUrl,
  routes,
  parseQuery,
  stringifyQuery,
  fallback: true,
  linkActiveClass: '_active',
  linkExactActiveClass: '_exact-active',
})

router.beforeEach(checkAuth)
router.beforeEach(checkDuplicate)

export function useStaticRouter() {
  return router
}

/**
 * * @AlexQuidditch
 *  useRoute для Vue 2 + Composition API работает криво
 * TODO: заменить при миграции на Vue 3
 */
export function useRoute(): Route {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new ReferenceError(
      'Vue instance not found.\n'
      + 'It looks like you trying to useRoute() outside setup() function',
    )
  }
  return vm.proxy.$route
}

// TODO: заменить при миграции на Vue 3
export function useRouter(): Router {
  const vm = getCurrentInstance()
  if (!vm) {
    throw new ReferenceError(
      'Vue instance not found.\n'
      + 'It looks like you trying to useRouter() outside setup() function',
    )
  }
  return vm.proxy.$router
}

export class Routes {
  public static get of() {
    return names
  }
}

export default router
