import { RouteConfig } from 'vue-router/types'

import { DialogsNames } from '@/router/names'

import Wrapper from '@/components/wrapper.vue'

const List = () => import(/* webpackChunkName: "workers" */ '@/pages/Dialogs.vue')

const routes: RouteConfig[] = [
  {
    path: DialogsNames.path,
    component: Wrapper,

    children: [
      {
        path: ':id?/:section?',
        name: DialogsNames.LIST,
        component: List,
        props: ({ query, params }) => ({ ...query, ...params }),
      },

      {
        path: '',
        name: DialogsNames.CREATE,
        redirect: () => ({
          name: DialogsNames.LIST,
          query: { 'add-dialog': 'true' },
        }),
      },
    ],
  },
]

export default routes
